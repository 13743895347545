import { SET_PAY, MAKE_PAY,SET_LOGIN,SET_IPActivity,SET_PAY_INFO, GET_PAY,SET_SHIPPING_AMT, SET_DISEASE, SET_PHYSICIAN,SET_CONSENT, GET_OTP, SET_PASS, SET_REQUEST, SET_VIAL,CLIENT_ACTIVITY, SET_DOSE,SET_CLIENT } from "../actions/types";

const isEmpty = require("is-empty");

const initialState = {
  pay: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PAY:
      return {
        ...state,
        page_name: action.payload.result
      };      
      case SET_CLIENT:
      return {
        ...state,
        client_info: action.payload.result
      };
      case SET_DOSE:
      return {
        ...state,
        dose_info: action.payload.result
      };
      case MAKE_PAY:
        return {
          ...state,
          payment_info: action.payload
        };
      case SET_LOGIN:
      return {
        ...state,
        res: action.payload
      };
      case SET_IPActivity:
      return {
        ...state,
        res: action.payload.result
      };
      case CLIENT_ACTIVITY:
      return {
        ...state,
        page_name: action.payload.result
      };
      case SET_PAY_INFO:
      return {
        ...state,
        payment_info: action.payload
      };
      case GET_OTP:
      return {
        ...state,
        res: action.payload.result
      };
      case SET_PASS:
      return {
        ...state,
        response: action.payload.result
      };
      case GET_PAY:
      return {
        ...state,
        pay_info: action.payload.result
      };
      case SET_DISEASE:
      return {
        ...state,
        disease_info: action.payload
      };
      case SET_PHYSICIAN:
      return {
        ...state,
        physician_info: action.payload
      };
      case SET_VIAL:
      return {
        ...state,
        vial_info: action.payload.result
      };
      case SET_CONSENT:
      return {
        ...state,
        consent_info: action.payload
      };
      case SET_REQUEST:
      return {
        ...state,
        request_info: action.payload
      };
      case SET_SHIPPING_AMT:
      return {
        ...state,
        shipping_charges: action.payload
      };
      
    default:
      return state;
  }
}
