/* eslint-disable no-unused-vars */
import React, { Component , useRef}  from "react";
import  { Redirect } from 'react-router-dom'
// import  { useRef   }  from "react";
// import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from 'moment'
// import validator from 'validator';
import MainLayout from "../layout/main-layout";
import Header from '../layout/Header';
import Left from '../layout/Left';
import "react-datepicker/dist/react-datepicker.css";
// import ReactSession from 'react-client-session';
import { getClientActivity  } from '../../actions/payActions'
import MUIDataTable from "mui-datatables";
import { date } from "joi";
class ClientActivity extends Component {


    constructor(props){
        super(props);
        this.state={
            error:'',
            errors:{},
            ClientData:[],
            IPData:[],
            formData:{},
            login:true,
            loading:true,
            tab:"Dose",
            checkCloseDate : new Date('2021-12-01')          
        }
    }
    
    componentDidMount() {   
        var search = ""        
        
          
        var client_id = localStorage.getItem('client_id');
        if(client_id != null){           
            this.props.getClientActivity(client_id)
          
            this.setState({'client_id':client_id})
            // console.log('physician_id => ',physician_id)
        }else{
            return <Redirect to='/home'  />
        }
                
    }

    componentWillReceiveProps(nextProps) {
        console.log('nextProps ----------',nextProps); 
        if(nextProps.page_name.page_name){
            this.state.ClientData = nextProps.page_name.page_name
            // console.log('form data', this.state.DoseData) 
            this.setState({loading:false})
        } 
        
    
       

    }



  
    render() {
        const {DoseData,checkCloseDate} = this.state
        // var data = []
        const columns = ["Client ID", "Client Name","Cell Vials Ordered",  "Date", "Type", "Plan Purchased", "Status","Cell Request Approved By Practitioner"];
        // const columns2 = ["Client ID", "Client Name", "Initial Sample Form","Client Consent Form","Infectious Disease Screening", "Annual Storage Paid", "Banked In Date", "Cell Vials Available Immediately","Action"];
        const columns2 = ["Client ID", "Client Name",  "Banked In Date", "Initial Sample Form","Client Consent Form","Infectious Disease Screening", "Annual Storage/Other Fees Paid", "Cell Vials Available Immediately","Action"];
        const options = {
            selectableRows: false,
            download:false,        
          };

        const orderCell =(e) => {
            const num = e.target.id
            // console.log("order cell ", this.state.DoseData[num])    
            localStorage.setItem('DoseData', JSON.stringify(this.state.DoseData[num]))        
            this.props.history.push('../stem-cell-request/'+ this.state.DoseData[num].opportunityRecordId)     
    
        }

  

        var data1 = []
            { this.state.ClientData.map((dt, index) => (                
                data1.push({"Client ID":dt.Client__r.Patient_ID__c, "Client Name" : dt.Client_Name_hidden_for_dose__c,"Cell Vials Ordered":function (){
                    return (dt.Dose_Amount__c)?dt.Dose_Amount__c: 'NA'},"Date":moment(dt.CloseDate).format('MM-DD-YYYY'),"Type":dt.Type.replace('Fee',''),"Plan Purchased":dt.Purchased_Plan_Name__c,"Status":dt.StageName,"Cell Request Approved By Practitioner":function(){
                        return (dt.Type=="Dose Retrieval Fee" && checkCloseDate.getTime() < new Date(dt.CloseDate).getTime())?
                         (dt.Cell_Request_Approved_By_Practitioner__c)?
                            <span className="mdi mdi-check-bold" />:<span style={{color:"red"}} className="mdi mdi-window-close" />
                            : 'NA'
                    }})))}

        var data2 = []
      
       
           
        //    console.log("data 1 =>" ,data1)
        
       
        if(!sessionStorage.getItem('client_id') || sessionStorage.getItem('client_id')==null) {
            return <Redirect to="../" />
          }
        return (
            <MainLayout>
                <Header />

                { (this.state.loading)?
                <div class="loading-div">
                    <div class="loading-img"><img src="images/spinner.svg" alt="" /></div>
                </div>:""}

                <div className="main-content">
                    <Left activeMenu={"client-activity"}/>  
                    <div class="right-section">
                        <div class="pagesubheader-wrap">
                        <div class="pagesubheader-row">
                            <div class="page-subheader-col-left">
                                <div class="title-avatar">
                                    <img src="images/physician-icon.svg" alt="" />
                                </div>
                                <div class="title-main">
                                    <span class="title-sub-text">Client Portal</span>
                                    <h1>Client Activity</h1>
                                </div>
                            </div>
                        </div>
                        </div>

                        

                        <div class="inner-content-area">
                        {/* <div class="search-input col-md-6">
                            <i><img style={{marginLeft:"20px"}} src="images/icon_search.png" alt="" /></i>
                            <div> </div>
                            <input id="Search" style={{width:"200px"}} type="search" class="form-control" placeholder="Search Data..." onChange={this.onChange} />
                             <button style={{position:'absolute',top: '0px',left: "227px"}} className="btn btn-primary" onClick={this.searchData}> Search</button>                            
                             <button style={{position:'absolute',top: '0px',left: "308px"}} className="btn btn-secondary" onClick={this.Reset}> Refresh </button>                            
                             <span style={{color: "red"}}>{this.state.errors["Search"]}</span>
                             <br />
                        </div> */}
                        <br />
                        <div class="patient-details-tabs">
                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li class="nav-item">
                                    <a class="nav-link active"  id="pills-related-tab" data-toggle="pill" href="#pills-related" role="tab" aria-controls="pills-related" aria-selected="false">Client Data</a>
                                </li>
                            {/* <li class="nav-item">
                                <a class="nav-link " id="pills-details-tab" data-toggle="pill" href="#pills-details" role="tab" aria-controls="pills-details" aria-selected="true">Cell Retrieval Activity</a>
                            </li> */}
                            
                        </ul>
                            <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade  show active" id="pills-related" role="tabpanel" aria-labelledby="pills-related-tab">
                                <div class="table-responsive">
                                <MUIDataTable
                                    title={"Client Activity"}
                                    data={data1}
                                    columns={columns}
                                    options={options}
                                    />                                
                                </div>
                                {/* <p>Note: ACT requires 2 weeks notice on Cell Request Orders if there are not cells available for immediate use.</p> */}
                            </div>
                            <div class="tab-pane fade" id="pills-details" role="tabpanel" aria-labelledby="pills-details-tab">
                                <div class="patient-details-content">
                                <div class="table-responsive">
                                {/* <MUIDataTable
                                    title={"Patient Data"}
                                    data={data2}
                                    columns={columns2}
                                    options={options}
                                    />                                 */}
                                {/* </div> */}

                                    
                                </div>
                                <p>Note: ACT requires 3 weeks notice on Cell Request Orders if there are not cells available for immediate use.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>
                </div>
            </MainLayout>
        );
    }
}

ClientActivity.propTypes = {
    errors: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    getPhysician:PropTypes.func.isRequired,
    getClientActivity: PropTypes.func.isRequired,
   
};

const mapStateToProps = (state) => ({
    page_name : state.pay,
    errors: state.errors,
  });


export default connect(mapStateToProps, {getClientActivity })(ClientActivity);