/* eslint-disable no-unused-vars */
import React, { Component , useRef}  from "react";
// import  { useRef   }  from "react";
// import { Link } from "react-router-dom";
import  { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from 'moment';
import validator from 'validator';
import MainLayout from "../layout/main-layout";
import Header from '../layout/Header';
import Left from '../layout/Left';
import DatePicker from "react-datepicker";
import valid from "card-validator";
import "react-datepicker/dist/react-datepicker.css";
import { getClient, updateClient  } from '../../actions/payActions'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from "moment";


class ClientInforation extends Component {

    constructor(props){
        super(props);
        this.state={
            error:'',
            errors:{},
            formData:{},
            login:true,
            Name:"",
            ClientId:"",
            Email:"",
            Phone:"",            
            loading:true,
            checked:"",
            form_disable:true,
            form_edit:false,
            clientDob:"",
            annualStoragePaidUntil_new:"Overdue",
            Curr_date : Date()
            
        }
    }

    componentDidMount() {      
        var client_id = localStorage.getItem('client_id'); 

        var cID = sessionStorage.getItem('client_id');
        // console.log('session ------------------ ',cID );

        // console.log('client_id ---------- ', client_id);
       
        this.setState({'client_id':client_id})
        this.setState({'client_id':client_id})
        if(client_id != null){
            this.props.getClient(client_id,this.props.history);

        }else{
            console.log('hello web')
            window.location = '../';
        }
       
       
        
                
    }

    componentWillReceiveProps(nextProps) {
        console.log('nextProps ----------',nextProps); 
        console.log('val ------------ ', localStorage.getItem('client_id'));
        
        
        if(nextProps.page_name.physician_info){
            this.setState({update_response:nextProps.page_name.physician_info})
            // this.setState({loading:false})
          }

        if(nextProps.page_name.page_name && nextProps.page_name.page_name.listResponse.length >0 ){
            let clientRecord = nextProps.page_name.page_name.listResponse[0];
            console.log('listResponse -------- ',clientRecord)
            console.log(Date(clientRecord.annualStoragePaidUntil) >= Date());
            console.log('date checkedddddddddddddddd')
            console.log(Moment(clientRecord.annualStoragePaidUntil).format('MM-DD-YYYY'))
            this.state.formData['ClientId'] = clientRecord.clientRecordId
            this.state.formData['firstName'] = clientRecord.firstName
            this.state.formData['lastName'] = clientRecord.lastName
            if(clientRecord.clientDob != null){
            this.state.formData['clientDob'] = Moment(clientRecord.clientDob).format('YYYY-MM-DD')
            // this.state.formData['clientDob_new'] = Moment(clientRecord.clientDob).format('MM/DD/YYYY')
            
            this.setState({clientDob:Moment(clientRecord.clientDob).toDate()})
            }
            // moment(dateString).toDate();
            this.state.formData['longevityVialsAvailable'] = clientRecord.longevityVialsAvailable
            this.state.formData['freeBioInsuranceVialsAvailble'] = clientRecord.freeBioInsuranceVialsAvailble
            this.state.formData['initialProcessingFormCompleted'] = clientRecord.initialProcessingFormCompleted
            this.state.formData['infectiousDiseaseFormCompleted'] = clientRecord.infectiousDiseaseFormCompleted
            this.state.formData['clientConsentFormCompleted'] = clientRecord.clientConsentFormCompleted
            this.state.formData['hasOverDuePayments'] = clientRecord.hasOverDuePayments
            this.state.formData['arbSubscribed'] = clientRecord.arbSubscribed
            this.state.formData['clientRecordId'] = clientRecord.clientRecordId

            if(clientRecord.annualStoragePaidUntil != null && Date(clientRecord.annualStoragePaidUntil) >= Date() ){
           // this.state.formData['annualStoragePaidUntil'] = Moment(clientRecord.annualStoragePaidUntil).format('YYYY-MM-DD');
            this.setState({annualStoragePaidUntil_new: Moment(clientRecord.annualStoragePaidUntil).format('MM-DD-YYYY') })
            }

            

           
            this.state.formData['clientACTId'] = clientRecord.clientACTId

            localStorage.setItem('clientACTId', clientRecord.clientACTId);
            localStorage.setItem('ClientLastName', clientRecord.lastName);
           
            console.log('clientACTId ---------- ', localStorage.getItem('clientACTId'));
            console.log('ClientLastName ---------- ', localStorage.getItem('ClientLastName'));
            
            this.state.formData['creditCardNumber'] = clientRecord.creditCardNumber
            

            this.state.formData['clinicName'] = clientRecord.clinicName
            this.state.formData['preferredAddressContactFullName'] = clientRecord.preferredAddressContactFullName
            this.state.formData['shippingClinicEmail'] = clientRecord.shippingClinicEmail
          
            this.state.formData['city'] = clientRecord.city
            this.state.formData['country'] = clientRecord.country
            this.state.formData['zip'] = clientRecord.zip
            this.state.formData['state'] = clientRecord.state
            this.state.formData['street'] = clientRecord.street
            this.state.formData['phone'] = clientRecord.phone
            this.state.formData['email'] = clientRecord.email

            this.state.formData['preferredAddressCity'] = clientRecord.preferredAddressCity
            this.state.formData['preferredAddressCountry'] = clientRecord.preferredAddressCountry
            this.state.formData['preferredAddressZip'] = clientRecord.preferredAddressZip
            this.state.formData['preferredAddressState'] = clientRecord.preferredAddressState
            this.state.formData['preferredAddressStreet'] = clientRecord.preferredAddressStreet
            this.state.formData['preferredAddressPhone'] = clientRecord.preferredAddressPhone
            
            // this.state.formData['E_Signature_Physician_Agreement'] = clientRecord.E_Signature_Physician_Agreement__c
            // this.state.formData['Physician_Form_Agreement_Hidden'] = clientRecord.Physician_Form_Agreement_Hidden__c
            this.setState({Form_Agreement:clientRecord.Physician_Form_Agreement_Hidden__c})
            this.setState({Name:clientRecord.name})
            this.setState({Email:clientRecord.Email})
            this.setState({Phone:clientRecord.Phone})
            this.setState({ClientId:clientRecord.clientRecordId})
            this.setState({loading:false})
            localStorage.setItem('client_id',clientRecord.clientRecordId);
            this.setState({checked:clientRecord.Physician_Form_Agreement_Hidden__c})

            // console.log('form data', this.state.formData)

            // console.log('client_id ------ ',localStorage.getItem('client_id') )
            this.setState({client_id:localStorage.getItem('client_id')})
            
            
            // this.setState({transactionId:nextProps.page_name.page_name.transactionId});           
            // let element = 
          }else{
            window.location = '../';
          }
          
        

    }

    onChange = (e) => {  
        this.state.formData[e.target.id] = e.target.value; 
        console.log('form vlaue=>', this.state.formData);
        let field_id = e.target.id
        this.setState({field_id:e.target.value})
    }

    handleCheck = (e) => {        
        this.state.formData[e.target.id] = !this.state.checked; 
    }


    datechange3(e){
        var dt = new Date(e);
        // this.state.formData['clientDob'] = Moment(e).format('MM/DD/YYYY');
        this.state.formData['clientDob'] = Moment(e).format('YYYY-MM-DD');

        // this.state.formData['clientDob'] = dt.getFullYear()+'-'+dt.getMonth()+'-'+dt.getDate();

        console.log('selected date =>', this.state.formData['clientDob']);
        console.log('date selected ->>>>>>>>>>>',Moment(dt).format('MM/DD/YYYY'));
        this.setState({clientDob:e})
    }

    submit = (e) => {
        e.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure you want to update this information?</h1>
                  <p></p>
                  <button onClick={onClose}>No</button>
                  <button
                    onClick={() => {
                        this.onSubmit(e);
                      onClose();
                    }}
                  >
                    Yes
                  </button>
                </div>
              );
            }
          });
        // confirmAlert({
        //   title: 'Confirm to submit',
        //   message: 'Are you sure to do this.',
        //   buttons: [
        //     {
        //       label: 'Yes',
        //       onClick: () => this.onSubmit(e)
        //     },
        //     {
        //       label: 'No',
        //       onClick: () => alert('Click No')
        //     }
        //   ]
        // });
    };

    editForm = (e) =>{
        this.setState({form_edit:true})
        this.setState({form_disable:false})
    }

    cancleForm = (e) =>{
        this.setState({form_edit:false})
        this.setState({form_disable:true})
    }

    

    onSubmit = (e) => {              
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;

        if(!fields["preferredAddressContactFullName"]){
            formIsValid = false;
            errors["preferredAddressContactFullName"] = "Please enter clinic contact person name";
            let element = document.getElementById("preferredAddressContactFullName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["clinicName"]){
            formIsValid = false;
            errors["clinicName"] = "Please enter clinic name";
            let element = document.getElementById("clinicName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        
        

        if(!fields["shippingClinicEmail"]){
            formIsValid = false;
            errors["shippingClinicEmail"] = "Please enter shipping email";
            let element = document.getElementById("shippingClinicEmail");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(fields["shippingClinicEmail"] && !validator.isEmail(fields["shippingClinicEmail"])) {
            formIsValid = false;
            errors["shippingClinicEmail"] = "Invalid email.";
            let element = document.getElementById("shippingClinicEmail");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error" 
             
        }



        if(!fields["preferredAddressPhone"]){
            formIsValid = false;
            errors["preferredAddressPhone"] = "Please enter phone nuber";
            let element = document.getElementById("preferredAddressPhone");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(fields["preferredAddressPhone"] && isNaN(fields["preferredAddressPhone"])){
            formIsValid = false;
            let postalcode = valid.postalCode(fields["preferredAddressPhone"]);
            // console.log('postalcode',postalcode);
            errors["preferredAddressPhone"] = "Please insert only number";
            let element = document.getElementById("preferredAddressPhone");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
         } 

        

        if(!fields["preferredAddressCity"]){
            formIsValid = false;
            errors["preferredAddressCity"] = "Please enter City";
            let element = document.getElementById("preferredAddressCity");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["preferredAddressState"]){
            formIsValid = false;
            errors["preferredAddressState"] = "Please enter State";
            let element = document.getElementById("preferredAddressState");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["preferredAddressCountry"]){
            formIsValid = false;
            errors["preferredAddressCountry"] = "Please enter Country";
            let element = document.getElementById("preferredAddressCountry");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        if(!fields["preferredAddressStreet"]){
            formIsValid = false;
            errors["preferredAddressStreet"] = "Please enter Street";
            let element = document.getElementById("preferredAddressStreet");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["preferredAddressZip"]){
            formIsValid = false;
            errors["preferredAddressZip"] = "Please enter zipcode";
            let element = document.getElementById("preferredAddressZip");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        // if(fields["preferredAddressZip"] && isNaN(fields["preferredAddressZip"])){
        //     formIsValid = false;
        //     let postalcode = valid.postalCode(fields["preferredAddressZip"]);
        //     // console.log('postalcode',postalcode);
        //     errors["preferredAddressZip"] = "Invalid Zip Code";
        //     let element = document.getElementById("preferredAddressZip");                
        //     const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        //     window.scrollTo({top: y, behavior: 'smooth'});
        //     element.className = "form-control error"
        //  } 

        if(!fields["city"]){
            formIsValid = false;
            errors["city"] = "Please enter City";
            let element = document.getElementById("city");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        

        if(!fields["clientDob"]){
            formIsValid = false;
            errors["clientDob"] = "Please enter Birthdate";
            let element = document.getElementById("clientDob");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(fields["clientDob"] == "Invalid date"){
            formIsValid = false;
            errors["clientDob"] = "Please enter Birthdate";
            let element = document.getElementById("clientDob");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["email"]){
            formIsValid = false;
            errors["email"] = "Please enter email";
            let element = document.getElementById("email");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        

        if(fields["email"] && !validator.isEmail(fields["email"])) {
            formIsValid = false;
            errors["email"] = "Invalid email.";
            let element = document.getElementById("email");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error" 
             
        }

        

        if(!fields["phone"]){
            formIsValid = false;
            errors["phone"] = "Please enter phone";
            let element = document.getElementById("phone");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(fields["phone"] && isNaN(fields["phone"])){
            formIsValid = false;
            let postalcode = valid.postalCode(fields["phone"]);
            // console.log('postalcode',postalcode);
            errors["phone"] = "Please insert only number";
            let element = document.getElementById("phone");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
         } 



        if(!fields["state"]){
            formIsValid = false;
            errors["state"] = "Please enter State";
            let element = document.getElementById("state");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["country"]){
            formIsValid = false;
            errors["country"] = "Please enter Country";
            let element = document.getElementById("country");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        if(!fields["street"]){
            formIsValid = false;
            errors["street"] = "Please enter Street";
            let element = document.getElementById("street");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["zip"]){
            formIsValid = false;
            errors["zip"] = "Please enter zipcode";
            let element = document.getElementById("zip");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        // if(isNaN(fields["zip"])){
        //     formIsValid = false;
        //     let postalcode = valid.postalCode(fields["zip"]);
        //     // console.log('postalcode',postalcode);
        //     errors["zip"] = "Invalid Zip Code";
        //     let element = document.getElementById("zip");                
        //     const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        //     window.scrollTo({top: y, behavior: 'smooth'});
        //     element.className = "form-control error"
        //  } 


        

     

        if(!fields["lastName"]){
            formIsValid = false;
            errors["lastName"] = "Cannot be empty";
            let element = document.getElementById("lastName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["firstName"]){
            formIsValid = false;
            errors["firstName"] = "Cannot be empty";
            let element = document.getElementById("firstName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        

        if(!fields["firstName"]){
            formIsValid = false;
            errors["firstName"] = "Cannot be empty";
            let element = document.getElementById("firstName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }



        // console.log('formIsValid --- ', formIsValid)

        this.setState({errors: errors});

        if(formIsValid){
            // this.setState({ loading: true });  
            this.setState({form_edit:false})
            this.setState({form_disable:true})
            const newList = this.state.formData;
            // console.log("newlist=>", newList);
            this.props.updateClient(newList, this.props.history);
            this.setState({loading:true})
            }

        

    }

    render() {
        const { formData,Name,Email,Id,Phone } = this.state;
        // console.log('formData -----', formData)
       
        // if(sessionStorage.getItem('consentForm') == false || sessionStorage.getItem('consentForm') == "false") {           
        // form_color = 'red';
        // }
        console.log('consent form 11---------- '+ sessionStorage.getItem('consentForm') + '---------- ')
        console.log('consent form 11 ---------- '+ localStorage.getItem('DiseaseForm') + '---------- ')

        

        if(!sessionStorage.getItem('client_id') || sessionStorage.getItem('client_id')==null) {           
            return <Redirect to="../" />
          }
        return (
            <MainLayout>
                <Header />
                { (this.state.loading)?
                <div class="loading-div">
                    <div class="loading-img"><img src="images/spinner.svg" alt="" /></div>
                </div>:""}

                <div className="main-content">
                    <Left activeMenu={"client-information"} />  

                    <div className="right-section">
                    <div className="pagesubheader-wrap">
                        <div className="pagesubheader-row">
                            <div className="page-subheader-col-left">
                                <div className="title-avatar">
                                    <img src="images/physician-icon.svg" alt="" />
                                </div>
                                <div className="title-main">
                                    <span className="title-sub-text">Client Portal</span>
                                    <h1>Client Information</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    { (this.state.update_response)?
                    <div className="col-md-12">
                        <h5 style={{paddingTop:"10px"}}><i> Client information successfully updated  ! </i></h5> 
                        </div>:
                    <div className="inner-content-area">

                   


                    <form className="needs-validation" noValidate >
                    <div className="form-wrap">
                        <div className="row">
                        <div className="col-md-12">
                        <div className="form-group" >
                        {(!formData.creditCardNumber)?
                        <div class="alert alert-danger col-md-12">
                        <strong>Alert!</strong> Please update your <a href="/payment-information" class="alert-link">payment information</a>.
                        </div>:""}
                       {(!formData.clientConsentFormCompleted)?
                        <div class="alert alert-danger col-md-12">
                        <strong>Alert!</strong> Please fill the <a href="/form-document/Consent-form" class="alert-link">consent form</a>.
                        </div>:""}
                        {(!formData.infectiousDiseaseFormCompleted)?
                        <div class="alert alert-danger col-md-12">
                        <strong>Alert!</strong> Please complete the <a href="/form-document/Infectious-disease" class="alert-link"> infectious disease submission</a>.
                        </div>:""}

                        <h4 style={{margin:"2%"}}>Update your payment information to pay any overdue annual storage fee(s).</h4>
                        </div>    
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Annual Storage Paid Until <span className="required">*</span></label>
                            <div className="field-col">

                                <input type="text" id="annualStoragePaidUntil" className="form-control" value={(this.state.annualStoragePaidUntil_new!=null)?this.state.annualStoragePaidUntil_new:"Overdue"} placeholder="" onChange={this.onChange} disabled  />
                                <span style={{color: "red"}}>{this.state.errors["annualStoragePaidUntil"]}</span>
                            </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6">
                            <div className="form-group">
                            <a className="btn btn-primary" style={{marginLeft:"4%"}} href="/payment-information" >Update Payment Information</a>
                         </div></div>
                        
                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Bio-Insurance Cells Available <span className="required">*</span></label>
                            <div className="field-col">
                                <input type="text" id="freeBioInsuranceVialsAvailble" className="form-control" value={formData.freeBioInsuranceVialsAvailble} placeholder="" onChange={this.onChange} disabled  />
                                <span style={{color: "red"}}>{this.state.errors["freeBioInsuranceVialsAvailble"]}</span>
                            </div>
                            </div>
                        </div>
                        {/* <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Longevity Cells Available<span className="required">*</span></label>
                            <div className="field-col">
                                <input type="text" id="longevityVialsAvailable" className="form-control" value={formData.longevityVialsAvailable} placeholder="" onChange={this.onChange} disabled />
                                <span style={{color: "red"}}>{this.state.errors["longevityVialsAvailable"]}</span>
                            </div>
                            </div>
                        </div> */}
                        
                        <div className="col-md-6">
                            <div className="form-group">
                            <a className="btn btn-primary" style={{marginLeft:""}} href="/ACT-banking-storage-plans" >Order Banking or Storage Plan</a>
                         </div></div>
                         <div className="col-md-12">                           
                            <div className="form-group">   
                            <h5 className="title-2 mb-4"> Client Information </h5>                                                   
                            </div></div>
                        {(!this.state.form_edit)?
                        <div className="col-md-12">                           
                            <div className="form-group">   
                            {/* <h5 className="title-2 mb-4"> Client Information </h5>                                                    */}
                            <div className="field-col">
                                <input type="button" className="btn btn-secondary mr-2" onClick={this.editForm} placeholder="" value="Edit" />
                            </div>                            
                        </div>
                        </div>:""}
                        
                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Client ID  </label>
                            <div className="field-col">
                                <input type="text" className="form-control" placeholder="" value={formData.clientACTId} disabled />
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group">
                        <label className="col-form-label">Client Birthdate <span className="required">*</span></label>
                        <div className="field-col">
                        <DatePicker id="clientDob" className="form-control bggray"  placeholderText="mm/dd/yyyy" selected={this.state.clientDob} disabled={this.state.form_disable}  onChange={date => this.datechange3(date)} /><br />
                        {/* <input type="text" id="Addendum_dob" className="form-control" placeholder="" onChange={this.onChange} /> */}
                        <span style={{color: "red"}}>{this.state.errors["clientDob"]}</span>
                        </div>
                        </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">First Name <span className="required">*</span></label>
                            <div className="field-col">
                                <input type="text" id="firstName" className="form-control" value={formData.firstName} placeholder="" onChange={this.onChange} disabled={this.state.form_disable}  />
                                <span style={{color: "red"}}>{this.state.errors["firstName"]}</span>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Last Name <span className="required">*</span></label>
                            <div className="field-col">
                                <input type="text" id="lastName" className="form-control" value={formData.lastName} placeholder="" onChange={this.onChange} disabled={this.state.form_disable}  />
                                <span style={{color: "red"}}>{this.state.errors["lastName"]}</span>
                            </div>
                            </div>
                        </div>                        
                        
                        
                        {/* <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Last Name <span className="required">*</span></label>
                            <div className="field-col">
                                <input type="text" id="LastName" className="form-control" value={formData.LastName} placeholder="" onChange={this.onChange} disabled={this.state.form_disable}  />
                                <span style={{color: "red"}}>{this.state.errors["LastName"]}</span>
                            </div>
                            </div>
                        </div> */}
                        {/* <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Clinic <span className="required">*</span></label>
                            <div className="field-col">
                                <input type="text" className="form-control" id="ClinicName" onChange={this.onChange} placeholder="" value={formData.ClinicName} disabled={this.state.form_disable}  />
                                <span style={{color: "red"}}>{this.state.errors["ClinicName"]}</span>
                            </div>
                            </div>
                        </div> */}

                        
                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Email <span className="required">*</span></label>
                            <div className="field-col">
                                <input type="text" id="email" className="form-control" value={formData.email} onChange={this.onChange} placeholder="" disabled={this.state.form_disable} />
                                <span style={{color: "red"}}>{this.state.errors["email"]}</span>
                            </div>
                            </div>
                        </div>

                        
                      
                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Phone Number <span className="required">*</span></label>
                            <div className="field-col">
                                <input type="text" id="phone" className="form-control" value={formData.phone} onChange={this.onChange} placeholder="" disabled={this.state.form_disable} />
                                <span style={{color: "red"}}>{this.state.errors["phone"]}</span>
                            </div>
                            </div>
                        </div>
                        
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Street <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="street" className="form-control" onChange={this.onChange} value={formData.street} placeholder="" disabled={this.state.form_disable} />
                                    <span style={{color: "red"}}>{this.state.errors["street"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">City <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="city" className="form-control" onChange={this.onChange} value={formData.city} placeholder="" disabled={this.state.form_disable} />
                                    <span style={{color: "red"}}>{this.state.errors["city"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">State <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="state" className="form-control" onChange={this.onChange} value={formData.state} placeholder="" disabled={this.state.form_disable} />
                                   
                                    <span style={{color: "red"}}>{this.state.errors["state"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Zip Code <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="zip" className="form-control" placeholder="" onChange={this.onChange} value={formData.zip} disabled={this.state.form_disable} />
                                    <span style={{color: "red"}}>{this.state.errors["zip"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Country <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="country" className="form-control" onChange={this.onChange} value={formData.country} placeholder="" disabled={this.state.form_disable} />
                                    <span style={{color: "red"}}>{this.state.errors["country"]}</span>
                                </div>
                                </div>
                            </div>


                        {/* <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Address</label>
                            <div className="field-col">
                                <input type="text" id="Address" className="form-control" onChange={this.onChange} placeholder="" />
                                <span style={{color: "red"}}>{this.state.errors["Address"]}</span>
                            </div>
                            </div>
                        </div> */}
                        {/* <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label pt-0">Physician Agreement Complete</label>
                            <div className="field-col custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="E_Signature_Physician_Agreement"  onChange={this.handleCheck} defaultChecked={this.state.Form_Agreement} disabled />
                                <label className="custom-control-label" for="E_Signature_Physician_Agreement"></label>
                            </div>
                            </div>
                        </div> */}
                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label pt-0">Initial Sample Form</label>
                            <div className="field-col custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="initialProcessingFormCompleted"  onChange={this.handleCheck} defaultChecked={formData.initialProcessingFormCompleted} disabled />
                                <label className="custom-control-label" for="initialProcessingFormCompleted"></label>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label pt-0">Client Consent Form</label>
                            <div className="field-col custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="clientConsentFormCompleted"  onChange={this.handleCheck} defaultChecked={formData.clientConsentFormCompleted} disabled />
                                <label className="custom-control-label" for="clientConsentFormCompleted"></label>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label pt-0">Infectious Disease Screening</label>
                            <div className="field-col custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="infectiousDiseaseFormCompleted"  onChange={this.handleCheck} defaultChecked={formData.infectiousDiseaseFormCompleted} disabled />
                                <label className="custom-control-label" for="infectiousDiseaseFormCompleted"></label>
                            </div>
                            </div>
                        </div>


                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label pt-0">Annual Storage Paid</label>
                            <div className="field-col custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="hasOverDuePayments"  onChange={this.handleCheck} defaultChecked={!formData.hasOverDuePayments} disabled />
                                <label className="custom-control-label" for="hasOverDuePayments"></label>
                            </div>
                            </div>
                        </div>

                        


                        


                        {/* <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label pt-0">Annual Storage/Other Fees Paid</label>
                            <div className="field-col custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="E_Signature_Physician_Agreement"  onChange={this.handleCheck} defaultChecked={this.state.Form_Agreement} disabled />
                                <label className="custom-control-label" for="E_Signature_Physician_Agreement"></label>
                            </div>
                            </div>
                        </div> */}



                        <div className="col-md-6 mob-none">
                            <div className="form-group">&nbsp;</div>
                        </div>
                        {/* <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Shipping Addresses </label>
                            <div className="field-col">
                                <textarea className="form-control" placeholder=""></textarea>
                                <div className="custom-control custom-checkbox mt-3">
                                <input type="checkbox" className="custom-control-input" id="customCheck2" />
                                <label className="custom-control-label" for="customCheck2">Saved Shipping Addresses</label>
                                </div>
                            </div>
                            
                            </div>
                        </div> */}
                        </div>

                        <div className="col-md-12">
                        <h2 className="title-2"> Preferred Shipping to Medical Professional</h2>
                        <div class="submission-detail-sec">
                        <div class="row"> 

                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Clinic Name <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="clinicName" className="form-control" onChange={this.onChange} value={formData.clinicName} placeholder="" disabled={this.state.form_disable} />
                                    <span style={{color: "red"}}>{this.state.errors["clinicName"]}</span>
                                </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Clinic Contact Person Full Name <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="preferredAddressContactFullName" className="form-control" onChange={this.onChange} value={formData.preferredAddressContactFullName} placeholder="" disabled={this.state.form_disable} />
                                    <span style={{color: "red"}}>{this.state.errors["preferredAddressContactFullName"]}</span>
                                </div>
                                </div>
                            </div>

                           

                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Shipping Clinic Email<span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="shippingClinicEmail" className="form-control" onChange={this.onChange} value={formData.shippingClinicEmail} placeholder="" disabled={this.state.form_disable} />
                                    <span style={{color: "red"}}>{this.state.errors["shippingClinicEmail"]}</span>
                                </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                    <div className="form-group">
                                    <label className="col-form-label">Phone <span className="required">*</span></label>
                                    <div className="field-col">
                                        <input type="text" id="preferredAddressPhone" className="form-control" onChange={this.onChange} value={formData.preferredAddressPhone} placeholder="" disabled={this.state.form_disable} />
                                        <span style={{color: "red"}}>{this.state.errors["preferredAddressPhone"]}</span>
                                    </div>
                                    </div>
                                </div>

                            <div className="col-md-6">
                                    <div className="form-group">
                                    <label className="col-form-label">Street <span className="required">*</span></label>
                                    <div className="field-col">
                                        <input type="text" id="preferredAddressStreet" className="form-control" onChange={this.onChange} value={formData.preferredAddressStreet} placeholder="" disabled={this.state.form_disable} />
                                        <span style={{color: "red"}}>{this.state.errors["preferredAddressStreet"]}</span>
                                    </div>
                                    </div>
                                </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">City <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="preferredAddressCity" className="form-control" onChange={this.onChange} value={formData.preferredAddressCity} placeholder="" disabled={this.state.form_disable} />
                                    <span style={{color: "red"}}>{this.state.errors["preferredAddressCity"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">State <span className="required">*</span></label>
                                <div className="field-col">
                                {/* <input type="text" id="preferredAddressState" className="form-control" onChange={this.onChange} value={formData.preferredAddressState} placeholder="" disabled={this.state.form_disable} /> */}
                                <select className="form-control" id="preferredAddressState" placeholder="" onChange={this.onChange} value={formData.preferredAddressState} disabled={this.state.form_disable}>
                                                <option value="">Select State</option>   
                                                <option value="AL">Alabama</option>
                                                <option value="AK">Alaska</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="DC">District Of Columbia</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NY">New York</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>
                                                </select>
                                    
                                    <span style={{color: "red"}}>{this.state.errors["preferredAddressState"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Zip Code <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="preferredAddressZip" className="form-control" placeholder="" onChange={this.onChange} value={formData.preferredAddressZip} disabled={this.state.form_disable} />
                                    <span style={{color: "red"}}>{this.state.errors["preferredAddressZip"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Country <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="preferredAddressCountry" className="form-control" onChange={this.onChange} value={formData.preferredAddressCountry} placeholder="" disabled={this.state.form_disable} />
                                    <span style={{color: "red"}}>{this.state.errors["preferredAddressCountry"]}</span>
                                </div>
                                </div>
                            </div>

                        </div>
                        </div>


                        </div>
                        {(this.state.form_edit)?
                        <div className="button-row">
                        <button type="button" className="btn btn-outline-primary mr-2" onClick={this.cancleForm}>Cancel</button>
                        <button type="button" onClick={this.submit} className="btn btn-primary">Save</button>
                        </div>:""}
                    </div>
                    </form>                    
                    </div>
                    }
                    </div>
                    

                </div>

            </MainLayout>
        );
    }
}

ClientInforation.propTypes = {
    errors: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    getClient:PropTypes.func.isRequired,    
    updateClient:PropTypes.func.isRequired,    
};


const mapStateToProps = (state) => ({
    page_name : state.pay,
    errors: state.errors,
  });


export default connect(mapStateToProps, {getClient,updateClient  })(ClientInforation);

// export default PhysicianInforation;