/* eslint-disable no-unused-vars */
import React, { Component , useRef}  from "react";
import  { Redirect } from 'react-router-dom'
// import  { useRef   }  from "react";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from 'moment';
import validator from 'validator';
import MainLayout from "../layout/main-layout";
import Header from '../layout/Header';
import Left from '../layout/Left';
import DatePicker from "react-datepicker";
import valid from "card-validator";
// import ReactSession from 'react-client-session';
import { getPaymentInfo, setPaymentInfo } from '../../actions/payActions'

class PaymentInformation extends Component {


    constructor(props){
        super(props);
        this.state={
            error:'',
            errors:{},
            paymentInfo:{},
            paymentInfo_update:{},
            formData:{}, 
            IPData:[],
            login:true, 
            msg:"",    
            loading:true,
            card_last_gid:"",
            creditinfoButton:"block",
            showEditOption:false,
        }
    }

    componentDidMount() {            
        
          
        var client_id = localStorage.getItem('client_id');
        // console.log('client_id ', client_id)
        if(client_id != ""){
            // console.log('imrn')
            // console.log('physician_id ', physician_id)
            this.state.paymentInfo['ClientSFRecordId'] = client_id
            this.props.getPaymentInfo(client_id,this.props.history);  
        }
                
    }

    componentWillReceiveProps(nextProps) {
        // var client_id = localStorage.getItem('client_id');
        // console.log('nextProps ----------',nextProps); 
        if(nextProps.page_name.pay_info){
            // this.state.paymentInfo = nextProps.page_name.page_name
            this.state.paymentInfo['Credit_Card_Holder_First_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_First_Name__c
            this.state.paymentInfo['Credit_Card_Holder_Last_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_Last_Name__c
            this.state.paymentInfo['Credit_Card_Billing_Zip_Code'] = nextProps.page_name.pay_info.Credit_Card_Billing_Zip_Code__c
            this.state.paymentInfo['CVV'] = nextProps.page_name.pay_info.CVV__c
            this.state.paymentInfo['Credit_Card_Number'] = nextProps.page_name.pay_info.Credit_Card_Number__c
            this.state.paymentInfo['Credit_Card_Type'] = nextProps.page_name.pay_info.Credit_Card_Type__c
            this.state.paymentInfo['Month_of_Expiry'] = nextProps.page_name.pay_info.Month_of_Expiry__c
            this.state.paymentInfo['Year_of_Expiry'] = nextProps.page_name.pay_info.Year_of_Expiry__c
            this.state.paymentInfo['ClientSFRecordId'] = nextProps.page_name.pay_info.Id
            this.state.formData['ClientSFRecordId'] = nextProps.page_name.pay_info.Id

            if(this.state.paymentInfo['Month_of_Expiry'] && this.state.paymentInfo['Year_of_Expiry']){
                const d = new Date();
                let month = d.getMonth();
                let year = d.getFullYear();

                // console.log('month --------', month);
                // console.log('year --------', year);
            }
            
            var nu =  nextProps.page_name.pay_info.Credit_Card_Number__c
            this.state.card_last_gid = nu.slice(nu.length - 4)
            this.setState({loading:false})
            // console.log('paymentInfo data', this.state.paymentInfo) 
        } 

        if(nextProps.page_name.payment_info){

            this.setState({loading:false})
            // let navigate = Redirect();
           
            if(nextProps.page_name.payment_info.error){
                this.setState({api_error:nextProps.page_name.payment_info.error})
            }else{
                this.state.formData['TransactionId'] = nextProps.page_name.payment_info.TransactionId
                this.setState({payment_response:nextProps.page_name.payment_info.page_name})
            }
            
            this.setState({loading:false})
            window.scrollTo(0, 0); 
            
            setTimeout(function(){
                window.location.reload();
             }, 5000);
            
            // this.props.getPaymentInfo(client_id,this.props.history);  

            
            // navigate("/payment-information")

            
    
        }


        if(nextProps.page_name.res){
            this.state.msg = nextProps.page_name.res
            this.setState({loading:false})
        }


    }

    onChange = (e) => {  
        this.state.formData[e.target.id] = e.target.value; 
        // console.log('paymentInfo value=>', this.state.formData);
    }

    showHidecreditinfo = (e) => {

        console.log('hello');
        console.log('hello-------- ',this.state.showEditOption);

        const currentState = this.state.showEditOption;
        this.setState({ showEditOption: !currentState });

        



    }

    onSubmit = (e) => {              
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;
        
        if(!fields["Credit_Card_Number"]){
            formIsValid = false;
            errors["Credit_Card_Number"] = "Please enter credit card number";
            let element = document.getElementById("Credit_Card_Number");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        if(fields["Credit_Card_Number"] &&  isNaN(fields["Credit_Card_Number"])) {
            let cardvalid = valid.number(fields["Credit_Card_Number"]);
             console.log('cardvalid',cardvalid);
            // if(!cardvalid.isValid){
                formIsValid = false;
                errors["Credit_Card_Number"] = "Invalid credit card number.";
                let element = document.getElementById("Credit_Card_Number");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            // }
        }

        
        if(!fields["Credit_Card_Biling_Email_Address"]){
            formIsValid = false;
            errors["Credit_Card_Biling_Email_Address"] = "Cannot be empty";
            let element = document.getElementById("Credit_Card_Biling_Email_Address");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }


        if(fields["Credit_Card_Biling_Email_Address"] && !validator.isEmail(fields["Credit_Card_Biling_Email_Address"])) {
            formIsValid = false;
            errors["Credit_Card_Biling_Email_Address"] = "Invalid email.";
            let element = document.getElementById("Credit_Card_Biling_Email_Address");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error" 
             
        }


        if(!fields["Credit_Card_Holder_First_Name"]){
            formIsValid = false;
            errors["Credit_Card_Holder_First_Name"] = "Field can't be empty";
            let element = document.getElementById("Credit_Card_Holder_First_Name");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }


        if(!fields["Credit_Card_Holder_Last_Name"]){
            formIsValid = false;
            errors["Credit_Card_Holder_Last_Name"] = "Field can't be empty";
            let element = document.getElementById("Credit_Card_Holder_Last_Name");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["CVV"]){
            formIsValid = false;
            errors["CVV"] = "Field can't be empty";
            let element = document.getElementById("CVV");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["Credit_Card_Type"]){
            formIsValid = false;
            errors["Credit_Card_Type"] = "Field can't be empty";
            let element = document.getElementById("Credit_Card_Type");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }



        if(fields["CVV"] && !/^\d{3,4}$/.test(fields["CVV"])) {
            formIsValid = false;
            errors["CVV"] = "Invalid CVV code"; 
            let element = document.getElementById("CVV");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});   
            element.className = "form-control error"            
        }

        if(!fields["Month_of_Expiry"]){
            formIsValid = false;
            errors["Month_of_Expiry"] = "Cannot be empty";
            let element = document.getElementById("Month_of_Expiry");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
        } 

        if(fields["Month_of_Expiry"]){

            let expMonth = valid.expirationMonth(fields["Month_of_Expiry"]);
            // console.log('expmonth',expMonth.isPotentiallyValid);
            if(!expMonth.isPotentiallyValid){
                formIsValid = false;
                errors["Month_of_Expiry"] = "Invalid credit Card Expiration Month";
                let element = document.getElementById("Month_of_Expiry");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
            }
        }

        if(!fields["Year_of_Expiry"]){
            formIsValid = false;
            errors["Year_of_Expiry"] = "Cannot be empty";
            let element = document.getElementById("Year_of_Expiry");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            } 

        if(fields["Year_of_Expiry"]){
            let expirationYear = valid.expirationYear(fields["Year_of_Expiry"]);
            // console.log('expirationYear',expirationYear.isPotentiallyValid);
            if(!expirationYear.isPotentiallyValid){
                formIsValid = false;
                errors["Year_of_Expiry"] = "Invalid credit Card Expiration Year";
                let element = document.getElementById("Year_of_Expiry");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
            }
        }

            if(fields["Year_of_Expiry"] && fields["Month_of_Expiry"]){
                let expirationDate = valid.expirationDate(fields["Month_of_Expiry"]+"/"+fields["Year_of_Expiry"]);

                let monthOfExp = fields["Month_of_Expiry"]-1;
                monthOfExp = (monthOfExp).toString().padStart(2,0);
                
                const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                const firstDate = new Date(fields["Year_of_Expiry"], monthOfExp, 1 );
                const dateToday = new Date();

                const diffDays = Math.round(Math.abs((firstDate - dateToday))/oneDay);

                console.log('Month_of_Expiry ----------- ', fields["Month_of_Expiry"]);
                console.log('monthOfExp ----------- ', monthOfExp);
                console.log('firstDate ----------- ', firstDate);
                console.log('secondDate ----------- ', dateToday);
                console.log('diffDays ----------- ', diffDays);

                if(diffDays < 365){
                    errors["Year_of_Expiry"] = "Please use credit card which has more than one year of expiry period remaining.";
                    let element = document.getElementById("Year_of_Expiry");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                }

                // console.log('expirationDate',expirationDate);
                if(!expirationDate.isPotentiallyValid){
                    formIsValid = false;
                    errors["Year_of_Expiry"] = "Something Wrong with Expiration Year";
                    let element = document.getElementById("Year_of_Expiry");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});

                    errors["Month_of_Expiry"] = "Something Wrong with Expiration Month";
                    let element1 = document.getElementById("Month_of_Expiry");                
                    element1.scrollIntoView({behavior: 'smooth'});
                    element.className = "form-control error"
                }
            }



        if(!fields["Credit_Card_Billing_Zip_Code"]){
            formIsValid = false;
            errors["Credit_Card_Billing_Zip_Code"] = "Field can't be empty";
            let element = document.getElementById("Credit_Card_Billing_Zip_Code");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["Country"]){
            formIsValid = false;
            errors["Country"] = "Field can't be empty";
            let element = document.getElementById("Country");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        // if(!fields["CVV"]){
        //     formIsValid = false;
        //     errors["CVV"] = "Field can't be empty";
        //     let element = document.getElementById("CVV");                
        //     const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        //     window.scrollTo({top: y, behavior: 'smooth'});
        //     element.className = "form-control error"
        // }

        // console.log('formIsValid --- ', formIsValid)

        this.setState({errors: errors});
        
        if(formIsValid){
            // this.setState({ loading: true });  
            const newList = this.state.formData;
            newList['PhysicianId'] = this.state.paymentInfo['PhysicianId']
            newList['PaymentSource'] = 'CP Payment Info Page'
            console.log("newlist=>", newList);
            this.setState({loading:true})
            this.props.setPaymentInfo(newList, this.props.history);
            }

    }    



    render() {
        const {paymentInfo} = this.state
        if(!sessionStorage.getItem('client_id') || sessionStorage.getItem('client_id')==null) {
           
            return <Redirect to="../" />
          }
        return (
            <MainLayout>
                <Header />
                { (this.state.loading)?
                <div class="loading-div">
                    <div class="loading-img"><img src="images/spinner.svg" alt="" /></div>
                </div>:""}
                <div className="main-content">
                    {( this.state.paymentInfo['Credit_Card_Number'] )?
                    <Left activeMenu={"payment-information"}/>:""}
                    <div class="right-section">
                    <div class="pagesubheader-wrap">
                        <div class="pagesubheader-row">
                            <div class="page-subheader-col-left">
                                <div class="title-avatar">
                                    <img src="images/credit-card.svg" alt="" />
                                </div>
                                <div class="title-main">
                                    <span class="title-sub-text">Client Portal</span>
                                    <h1>Payment Information</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    { (this.state.payment_response)?
                    <div className="col-md-12">
                        <h5 style={{paddingTop:"10px"}}><i> Your payment information has been updated sucessfully. We are now getting the portal services ready for you!</i> </h5>
                    </div>:
                    <div>
                    <div class="inner-content-area">
                    <b> <i>*AS Step 1, Please provide valid payment information to be securely stored.</i></b><br/>
                        <b><i>*This credit card will be charged for any overdue annual storage fee(s) and will be used for future annual storage fees if there is not a current payment method on file.</i></b>     
                        <br/><br/>
                    <span style={{color: "red"}}>{this.state.api_error}</span>   
                    {/* {(this.state.showEditOption) ? <h1> dddddddd </h1> : <h1>no</h1>} */}
                    <div className={(this.state.showEditOption) ? 'payment-info show-edit-option' : 'payment-info'}>
                        <form className="needs-validation" noValidate onSubmit={this.onSubmit}>
                        <div class="card">
                            <div class="card-header">
                                <h4>Current Payment Method Secured For Annual Banking </h4>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck2" checked />
                                    <label class="custom-control-label" for="customCheck2"></label>
                                    </div>
                            </div>
                            <div class="card-body">
                                <div class="saved-card-detail">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>Card Number</label>
                                            <div class="card-dtl-value">
                                                {(paymentInfo.Credit_Card_Type == 'Mastercard') ?<img class="card-logo" src="images/master-card.png" alt="" /> :""}
                                                {(paymentInfo.Credit_Card_Type == 'American Express') ?<img class="card-logo" src="images/card-3.svg" alt="" /> :""}
                                                {(paymentInfo.Credit_Card_Type == 'Discover') ?<img class="card-logo" src="images/card-4.svg" alt="" /> :""}
                                                {(paymentInfo.Credit_Card_Type == 'Visa') ?<img class="card-logo" src="images/card-1.svg" alt="" /> :""}
                                                
                                                
                                                {/* <img class="card-logo" src="images/master-card.png" alt="" /> */}
                                                <span>Credit Card ending in {this.state.card_last_gid}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <label>Name on card</label>
                                            <div class="card-dtl-value">
                                                <span>
                                                    {(paymentInfo.Credit_Card_Holder_First_Name)?
                                                    paymentInfo.Credit_Card_Holder_First_Name + " "+ paymentInfo.Credit_Card_Holder_Last_Name : "NA"}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <label>Expiry</label>
                                            <div class="card-dtl-value">
                                                <span>
                                                    {(this.state.paymentInfo.Month_of_Expiry)?                                                    
                                                    this.state.paymentInfo.Month_of_Expiry+ ' / '+ this.state.paymentInfo.Year_of_Expiry: "NA" }  </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-edit-option">
                                    <div class="update-card-row" id="edit_button"  >
                                        <h4>Update Credit Card Information For Annual Banking</h4> <a type="button" onClick={this.showHidecreditinfo} class="btn btn-primary btn-edit"><img src="images/edit.svg" alt="" /> Edit</a>
                                    </div>
                                    
                                    <div class="update-payment" >
                                    <h4>Update Payment Method</h4>
                                    <div class="card-detail-form">
                                        <div class="card-detail-wrap mb-5">
                                        <h5>Credit Card Detail <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title="Tooltip Text" alt="" /></h5>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Credit Card Number <span class="required">*</span> </label>
                                                    <input type="text" id="Credit_Card_Number" class="form-control" placeholder="" onChange={this.onChange} maxLength={16} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Number"]}</span>
                                                </div>
                                            </div>


                                            <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Credit Card Type <span class="required">*</span> </label>                                                
                                                <select className="custom-select d-block w-100" id="Credit_Card_Type" onChange={this.onChange} >
                                                    <option value="">Select Credit Card </option>
                                                    <option value="American Express">American Express</option>
                                                    <option value="Discover">Discover</option> 
                                                    <option value="Visa">Visa</option> 
                                                    <option value="Mastercard">Mastercard</option>                                                 
                                                </select>
                                                <span style={{color: "red"}}>{this.state.errors["Credit_Card_Type"]}</span>
                                            </div>
                                            </div>


                                            {/* <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Expiry Date <span class="required">*</span> </label>
                                                    <input type="text" id="" class="form-control" placeholder="-- --  /  -- -- -- --" />
                                                </div>
                                            </div> */}

                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>CVV <span class="required">*</span> </label>
                                                    <input id="CVV" type="password" class="form-control" placeholder="***" onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["CVV"]}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-3 mb-3">
                                            <label htmlFor="Month_of_Expiry">Card Expiration Date <span className="act_required">*</span></label>
                                            <select className="custom-select d-block w-100" id="Month_of_Expiry" onChange={this.onChange}  placeholder="mm" >
                                                <option value="">Select Month</option>
                                                <option value="01">01</option>
                                                <option value="02">02</option> 
                                                <option value="03">03</option> 
                                                <option value="04">04</option> 
                                                <option value="05">05</option>    
                                                <option value="06">06</option> 
                                                <option value="07">07</option> 
                                                <option value="08">08</option> 
                                                <option value="09">09</option> 
                                                <option value="10">10</option> 
                                                <option value="11">11</option>
                                                <option value="12">12</option>  
                                            </select>
                                            {/* <input type="text" className="form-control" id="Month_of_Expiry" onChange={this.onChange} placeholder="mm" required /> */}
                                            <span style={{color: "red"}}>{this.state.errors["Month_of_Expiry"]}</span>
                                            <div className="invalid-feedback">
                                            Valid Month required
                                            </div>
                                            </div>

                                                <div className="col-md-6 mb-3">
                                                <label htmlFor="creditCardExpirationYear"> &nbsp;</label>
                                                <select className="custom-select d-block w-100" id="Year_of_Expiry" onChange={this.onChange}  placeholder="YYYY" >
                                                <option value="">Year</option>
                                                {/* <option value="2021">2021</option> */}
                                               
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                                <option value="2029">2029</option>
                                                <option value="2030">2030</option>
                                                <option value="2031">2031</option>
                                                <option value="2032">2032</option>
                                                </select>
                                                {/* <input type="text" className="form-control" id="creditCardExpirationYear" onChange={this.onChange} placeholder="yy" required /> */}
                                                
                                                <span style={{color: "red"}}>{this.state.errors["Year_of_Expiry"]}</span><div className="invalid-feedback">
                                                Valid Year required
                                                </div>
                                                </div>

                                            
                                            <div class="col-md-12">
                                                <div class="card-logo-list">
                                                <div><img src="images/card-1.svg" alt="" /></div>
                                                <div><img src="images/card-2.svg" alt="" /></div>
                                                <div><img src="images/card-3.svg" alt="" /></div>
                                                <div><img src="images/card-4.svg" alt="" /></div>
                                                    </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="card-detail-wrap">
                                            <h5>Billing Address <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title="Tooltip Text" alt="" /></h5>
                                        <div class="row">
                                            
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>First Name</label>
                                                    <input type="text" id="Credit_Card_Holder_First_Name" class="form-control" placeholder="" onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_First_Name"]}</span>
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Last Name</label>
                                                    <input type="text" id="Credit_Card_Holder_Last_Name" class="form-control" placeholder="" onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_Last_Name"]}</span>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Biling Email</label>
                                                    <input type="email" id="Credit_Card_Biling_Email_Address" class="form-control" placeholder="" onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Biling_Email_Address"]}</span>
                                                </div>
                                            </div>


                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Country</label>
                                                    <input type="text" id="Country" class="form-control" placeholder="" onChange={this.onChange} /> 
                                                    <span style={{color: "red"}}>{this.state.errors["Country"]}</span>
                                                
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Zip/Postal Code</label>
                                                    <input type="text" id="Credit_Card_Billing_Zip_Code" class="form-control" placeholder="" onChange={this.onChange} /> 
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Billing_Zip_Code"]}</span>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                
                                </div>

                                

                            </div>
                            </div>

                            <div class="button-area">
                            <button type="button" onClick={this.showHidecreditinfo} class="btn btn-outline-primary mr-2">Cancel</button>
                            <button type="submit" class="btn btn-primary">Save</button>
                        </div>
                        </form>
                    </div>
                    </div> </div>} </div>
                    </div>                
            </MainLayout>
        );
    }
}

PaymentInformation.propTypes = {
    errors: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    setPaymentInfo:PropTypes.func.isRequired,    
    getPaymentInfo:PropTypes.func.isRequired,
    // showHidecreditinfo:PropTypes.func.isRequired,
};



const mapStateToProps = (state) => ({
    page_name : state.pay,
    errors: state.errors,
  });


export default connect(mapStateToProps, {getPaymentInfo, setPaymentInfo })(PaymentInformation);