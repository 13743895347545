/* eslint-disable no-unused-vars */
import React, { Component , useRef}  from "react";
import  { Redirect } from 'react-router-dom'
// import  { useRef   }  from "react";
// import { Link } from "react-router-dom";
import Moment from 'moment';
import validator from 'validator';
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from 'moment'
// import validator from 'validator';
import MainLayout from "../layout/main-layout";
import Header from '../layout/Header';
import Left from '../layout/Left';
import "react-datepicker/dist/react-datepicker.css";
import valid from "card-validator";
// import ReactSession from 'react-client-session';
import SignatureCanvas from 'react-signature-canvas'
import DatePicker from "react-datepicker";
import { getClient, getPaymentInfo,sendConsentData,setDiseaseData } from '../../actions/payActions'
// import MUIDataTable from "mui-datatables";
class PatientActivity extends Component {


    constructor(props){
        super(props);
        this.state={
            DoseData:[],
            IPData:[],
            formData:{},
            diseaseData:{'submissionOption':'','Amount':'399'},
            submission:"",
            login:true,
            tab:"consent_form",
            error:'',
            errors:{},          
            card_info:{},
            client_info:"",
            save_card:false,
            update_card:false,
            loading:false,
            payment_response:"",
            Dob:'',
            Client_id:"",
            Enrollment_Date:'',
            Addendum_dob:'',
            api_error:"",
            page:1,
            Medications:"no",
            Tissue_deliver_Box:false,  
            infectiousDiseaseFormCompleted:false,
            clientConsentFormCompleted:false,
            initialProcessingFormCompleted:false,
            tab1:"",
            tab2:"",
        }
    }
    
    componentDidMount() {   

       
        if(this.props.history.location.pathname== '/form-document/Consent-form'){
            this.setState({tab1:"show active"})
            this.setState({tab2:""})
        }else{
            this.setState({tab2:"show active"})
            this.setState({tab1:""})
        }
        this.setState({pathname:this.props.history.location.pathname })
        var client_id = localStorage.getItem('client_id'); 
        var clientACTId =  localStorage.getItem('clientACTId');
        var ClientLastName =  localStorage.getItem('clientLastName');
        var initialProcessingFormCompleted =  localStorage.getItem('initialProcessingFormCompleted');
        var infectiousDiseaseFormCompleted =  localStorage.getItem('infectiousDiseaseFormCompleted');
        var clientConsentFormCompleted =  localStorage.getItem('clientConsentFormCompleted');
        
        this.state.formData['Client_id'] = client_id
        this.setState({Client_id:client_id}) 

        if(infectiousDiseaseFormCompleted){
            this.setState({infectiousDiseaseFormCompleted:infectiousDiseaseFormCompleted})
        }

        if(initialProcessingFormCompleted){
            this.setState({initialProcessingFormCompleted:initialProcessingFormCompleted})
        }

        if(clientConsentFormCompleted){

            console.log('clientConsentFormCompleted ------- ', clientConsentFormCompleted);
            this.setState({clientConsentFormCompleted:clientConsentFormCompleted})
        }

         
         
        // console.log('client_id ---------- ', client_id);
        console.log('ClientLastName ---------- ', ClientLastName);
      
        this.setState({'Client_id':client_id})
        if(client_id && clientACTId){
            this.props.getClient(client_id,this.props.history);
            this.props.getPaymentInfo(client_id, this.props.history);
        }
       
       
       
                
    }

    componentWillReceiveProps(nextProps) {

        // console.log('nextProps -------- ',nextProps);

        if(nextProps.page_name.pay_info && this.state.update_card==false){
            this.state.formData['Credit_Card_Holder_First_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_First_Name__c
            this.state.formData['Credit_Card_Holder_Last_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_Last_Name__c
            this.state.formData['Credit_Card_Billing_Zip_Code'] = nextProps.page_name.pay_info.Credit_Card_Billing_Zip_Code__c
            this.state.formData['CVV'] = nextProps.page_name.pay_info.CVV__c
            this.state.formData['Credit_Card_Number'] = nextProps.page_name.pay_info.Credit_Card_Number__c
            this.state.formData['Month_of_Expiry'] = nextProps.page_name.pay_info.Month_of_Expiry__c
            this.state.formData['Year_of_Expiry'] = nextProps.page_name.pay_info.Year_of_Expiry__c
            this.setState({Credit_Card_Number:nextProps.page_name.pay_info.Credit_Card_Number__c})
            this.state.formData['ClientSFRecordId'] = nextProps.page_name.pay_info.Id
            this.state.formData['ClientId'] = localStorage.getItem('clientACTId');
            this.state.formData['ClientLastName'] = localStorage.getItem('clientLastName');

            this.state.diseaseData['Credit_Card_Holder_First_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_First_Name__c
            this.state.diseaseData['Credit_Card_Holder_Last_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_Last_Name__c
            this.state.diseaseData['Credit_Card_Billing_Zip_Code'] = nextProps.page_name.pay_info.Credit_Card_Billing_Zip_Code__c
            this.state.diseaseData['CVV'] = nextProps.page_name.pay_info.CVV__c
            this.state.diseaseData['Credit_Card_Number'] = nextProps.page_name.pay_info.Credit_Card_Number__c
           
            this.state.diseaseData['Month_of_Expiry'] = nextProps.page_name.pay_info.Month_of_Expiry__c
            this.state.diseaseData['Year_of_Expiry'] = nextProps.page_name.pay_info.Year_of_Expiry__c
            this.setState({Credit_Card_Number:nextProps.page_name.pay_info.Credit_Card_Number__c})
            this.state.diseaseData['ClientSFRecordId'] = nextProps.page_name.pay_info.Id
            this.state.diseaseData['ClientId'] = localStorage.getItem('clientACTId');
            this.state.diseaseData['ClientLastName'] = localStorage.getItem('clientLastName');
            
            // this.setState({card_info:nextProps.page_name.pay_info})
            this.state.card_info['Credit_Card_Number'] = nextProps.page_name.pay_info.Credit_Card_Number__c
            this.state.card_info['Credit_Card_Type'] = nextProps.page_name.pay_info.Credit_Card_Type__c
            this.state.card_info['CVV'] = nextProps.page_name.pay_info.CVV__c
            this.state.card_info['Credit_Card_Holder_First_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_First_Name__c
            this.state.card_info['Credit_Card_Holder_Last_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_Last_Name__c
            this.state.card_info['Month_of_Expiry'] = nextProps.page_name.pay_info.Month_of_Expiry__c
            this.state.card_info['Year_of_Expiry'] = nextProps.page_name.pay_info.Year_of_Expiry__c
            var nu =  nextProps.page_name.pay_info.Credit_Card_Number__c
            this.state.card_last_gid = nu.slice(nu.length - 4)
            // console.log('credit card => ',this.state.card_last_gid)
            // console.log('form data', this.state.card_info)
            this.setState({loading:false})
            window.scrollTo(0, 0);
            
          } 


          if(nextProps.page_name.consent_info){

            // this.setState({loading:false})
            this.state.formData['TransactionId'] = nextProps.page_name.consent_info.TransactionId
            this.setState({payment_response:nextProps.page_name.consent_info.page_name})
            if(nextProps.page_name.consent_info.error){
                this.setState({api_error:nextProps.page_name.consent_info.error})}
            else{
                this.setState({payment_response:nextProps.page_name.consent_info.page_name})
            }
            this.setState({loading:false})
            window.scrollTo(0, 0);     

            this.state.clientConsentFormCompleted = "true";

            console.log('consent form ---------- ',this.state.clientConsentFormCompleted);



            // setTimeout(function(){
            //     window.location.reload();
            //  }, 5000);

        }

        if(nextProps.page_name.disease_info){
            this.setState({loading:false})
            // this.setState({payment_response:nextProps.page_name.disease_info})
            this.setState({transaction_id:nextProps.page_name.disease_info.TransactionId})
            if(nextProps.page_name.disease_info.error){
                this.setState({api_error:nextProps.page_name.disease_info.error})
            }else{
                this.setState({payment_response:nextProps.page_name.disease_info.page_name})
            }
            window.scrollTo(0, 0); 
        }

        if(nextProps.page_name.page_name){
            console.log('annual storage paid ------------------- ',nextProps.page_name.page_name.listResponse[0].annualStoragePaidUntil);            
            this.state.formData['annualStoragePaidUntil'] = nextProps.page_name.page_name.listResponse[0].annualStoragePaidUntil;
        }


      
       
    }

    tabChange = (e) => {
        var ID_ = e.target.id
        var VAL = e.target.value        
        this.setState({tab:VAL}) 
    }

    checkRadio1 = (e) => {  
        if(e.target.id=="Tissue_deliver_2"){
            console.log("valie =========",)
            // this.state.Tissue_deliver_Box == "true";
            this.setState({Tissue_deliver_Box:true});
        } else{
            this.setState({Tissue_deliver_Box:false});
        }  
        this.state.formData['Tissue_deliver'] = e.target.value; 

    }

    checkRadio3 = (e) => {     
        if(this.state.update_card){
            this.setState({update_card:!this.state.update_card})
            this.state.formData["Credit_Card_Number"] = this.state.card_info['Credit_Card_Number']
            this.state.formData["CVV"] = this.state.card_info['CVV']
            this.state.formData["Credit_Card_Holder_First_Name"] = this.state.card_info['Credit_Card_Holder_First_Name']
            this.state.formData["Credit_Card_Holder_Last_Name"] = this.state.card_info['Credit_Card_Holder_Last_Name']
        }
        else{
            this.state.formData["Credit_Card_Number"] = ""
            this.state.formData["CVV"] = ""
            this.state.formData['Credit_Card_Holder_First_Name'] = ""
            this.state.formData['Credit_Card_Holder_Last_Name'] = ""

            this.setState({update_card:true})
        }
        
        // console.log('update_card =>', this.state.update_card)
    }

    checkRadio3Disease = (e) => {     
        if(this.state.update_card){
            this.setState({update_card:!this.state.update_card})
            this.state.diseaseData["Credit_Card_Number"] = this.state.card_info['Credit_Card_Number']
            this.state.diseaseData["CVV"] = this.state.card_info['CVV']
            this.state.diseaseData["Credit_Card_Holder_First_Name"] = this.state.card_info['Credit_Card_Holder_First_Name']
            this.state.diseaseData["Credit_Card_Holder_Last_Name"] = this.state.card_info['Credit_Card_Holder_Last_Name']
        }
        else{
            this.state.diseaseData["Credit_Card_Number"] = ""
            this.state.diseaseData["CVV"] = ""
            this.state.diseaseData['Credit_Card_Holder_First_Name'] = ""
            this.state.diseaseData['Credit_Card_Holder_Last_Name'] = ""

            this.setState({update_card:true})
        }
        
        // console.log('update_card =>', this.state.update_card)
    }

    

    checkbtn =(e)=> {
        // console.log("hello imran")
        this.state.formData['sample_type'] = e.target.value;
        // console.log('form sample type value => ', this.state.formData['sample_type'])
    }

    setcount = (e) => {

        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;
        

        
        if(!fields["Medications"]){
            formIsValid = false;
            errors["Medications"] = "Please Select One";
            let element = document.getElementById("Medications_Yes");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            // element.className = "form-control error"
        }
        if(!fields["Tissue_deliver"]){
            formIsValid = false;
            errors["Tissue_deliver"] = "Please Select One";
            let element = document.getElementById("Tissue_deliver_2");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            // element.className = "form-control error"
        }

        if(this.state.Tissue_deliver_Box){        

        if(!fields["Relationship"]){
            formIsValid = false;
            errors["Relationship"] = "Cannot be empty";
            let element = document.getElementById("Relationship");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["Addendum_phone"]){
            formIsValid = false;
            errors["Addendum_phone"] = "Cannot be empty";
            let element = document.getElementById("Addendum_phone");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(fields["Addendum_phone"] && isNaN(fields["Addendum_phone"]) ){
            formIsValid = false;
            errors["Addendum_phone"] = "Please insert only number";
            let element = document.getElementById("Addendum_phone");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        

        if(!fields["Addendum_address"]){
            formIsValid = false;
            errors["Addendum_address"] = "Cannot be empty";
            let element = document.getElementById("Addendum_address");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["Addendum_dob"]){
            formIsValid = false;
            errors["Addendum_dob"] = "Cannot be empty";
            let element = document.getElementById("Addendum_dob");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(fields["Addendum_dob"]){
            const dateFormat = 'MM-DD-YYYY';
            const yr = new Date(fields["Addendum_dob"]).getFullYear();

            const toDateFormat = Moment(new Date(fields["Addendum_dob"])).format(dateFormat);
            let validDate = Moment(toDateFormat, dateFormat, true).isValid();
            if(!validDate || yr >= 2050 ){
                formIsValid = false;
                errors["Addendum_dob"] = "Invalid date ";
                let element = document.getElementById("Addendum_dob");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
        }



        if(!fields["Addendum_name"]){
            formIsValid = false;
            errors["Addendum_name"] = "Cannot be empty";
            let element = document.getElementById("Addendum_name");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

    }


        // console.log('formIsValid --- ', formIsValid)

        this.setState({errors: errors});

        if(formIsValid){
        this.state.page = e.target.id; 
        this.setState({page:e.target.id})
        // console.log('page=>', this.state.page)
        window.scrollTo({top: "-20", behavior: 'smooth'});
        window.scrollTo(0, 0);
        }
    }

    setcount2 = (e) => {
        this.state.page = e.target.id; 
        this.setState({page:e.target.id})
        // console.log('page=>', this.state.page)
        window.scrollTo({top: "-20", behavior: 'smooth'});
        window.scrollTo(0, 0);
    }

    checkRadio =(e)=> {
        if(e.target.id == "Medications_Yes"){
            this.state.formData['Medications'] = e.target.value; 
            // this.setState({CreditCard:e.target.value})
            this.setState({Medications:"yes"})
        }

        if(e.target.id == "Medications_No"){
            this.state.formData['Medications'] = e.target.value; 
            // this.setState({CreditCard:e.target.value})
            // console.log('Medications =>', e.target.value)
            this.setState({Medications:"no"})
        }
        
        // console.log('Medications =>', this.state.formData)
        // console.log('target id  =>', e.target.id)
    }

    clear = () => {
        this.sigPad.clear()
        this.setState({ trimmedDataURL: null })
      }
    
    trim = () => {
    this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas()
        .toDataURL('image/png') })
        this.state.formData['Signature'] = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
        // console.log('img', this.sigPad.getTrimmedCanvas().toDataURL('image/png'));
        
    }

    clear = () => {
        this.sigPad.clear()
        this.setState({ trimmedDataURL: null })
      }
    
    trim2 = () => {
    this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas()
        .toDataURL('image/png') })
        this.state.diseaseData['Signature'] = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
        // console.log('img', this.sigPad.getTrimmedCanvas().toDataURL('image/png'));
        
    }

   
    datechange1(e){
        // new Date(e);
        this.state.formData['Dob'] = Moment(e).format('MM/DD/YYYY');
        // console.log('selected date =>', this.state.formData['Dob']);
        this.setState({Dob:e})
    }
    
    datechange2(e){
        // new Date(e);
        this.state.formData['Enrollment_Date'] = Moment(e).format('MM/DD/YYYY');
        // console.log('selected date =>', this.state.formData['Enrollment_Date']);
        this.setState({Enrollment_Date:e})
    }

    datechange3(e){
        this.state.formData['Addendum_dob'] = Moment(e).format('MM/DD/YYYY');
        // console.log('selected date =>', this.state.formData['Addendum_dob']);
        this.setState({Addendum_dob:e})
    }

    

    onChange = (e) => {  
        var ID_ = e.target.id
        var VAL = e.target.value
        this.state.formData[e.target.id] = e.target.value; 
        this.setState(formData => ({[ID_]:VAL }))  
        console.log('form vlaue=>', this.state.formData);
    }


    checkradiofordisease = (e) => {
        this.state.diseaseData['WaiverNotice'] = e.target.value;
        this.setState({client_test:e.target.id})
        
    }
    onChangeDisease = (e) =>{
        if(e.target.id == "submissionOption"){
            this.state.diseaseData[e.target.id] = e.target.value;
            this.setState({submission:e.target.value})
        }else{
            this.state.diseaseData[e.target.id] = e.target.value;
        }
    }


    savecard = (e) => {
        if(this.state.save_card){
            this.setState({save_card:!this.state.save_card})
            this.state.formData['save_card'] = !this.state.save_card
        }
        else{
            this.setState({save_card:true})

            this.state.formData['save_card'] = true
        }

        console.log('saved card -----', this.state.formData['save_card']);

    }

    savecard_dieses = (e) => {
        if(this.state.save_card){
            this.setState({save_card:!this.state.save_card})
            this.state.formData['save_card'] = !this.state.save_card
        }
        else{
            this.setState({save_card:true})

            this.state.formData['save_card'] = true
        }

        console.log('saved card -----', this.state.formData['save_card']);

    }

    onSubmit = (e) => {              
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;

        if(!fields["agree"]){
            formIsValid = false;
            errors["agree"] = "Please Check ";
            let element = document.getElementById("agree");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
           
        }

        if(!fields["Signature"]) {
            formIsValid = false;
            errors["Signature"] = "Please sign and click on check button.";
            // console.log('errors',errors);
            let element = document.getElementById("Signature");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }

        if(!fields["Medications"]){
            formIsValid = false;
            errors["Medications"] = "Please Select One";
            let element = document.getElementById("Medications_Yes");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            // element.className = "form-control error"
        }

        if(!fields["sample_type"]){
            formIsValid = false;
            errors["sample_type"] = "Please Select One";
            let element = document.getElementById("Bone-Marrow");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            // element.className = "form-control error"
        }


        if(this.state.update_card == true){

            if(!fields["Credit_Card_Number"]){
                formIsValid = false;
                errors["Credit_Card_Number"] = "Please enter credit card number";
                let element = document.getElementById("Credit_Card_Number");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(fields["Credit_Card_Number"]) {
                let cardvalid = valid.number(fields["Credit_Card_Number"]);
                // console.log('cardvalid',cardvalid);
                if(!cardvalid.isValid){
                    formIsValid = false;
                    errors["Credit_Card_Number"] = "Invalid credit card number.";
                    let element = document.getElementById("Credit_Card_Number");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                }
            }

            if(!fields["Credit_Card_Holder_First_Name"]){
                formIsValid = false;
                errors["Credit_Card_Holder_First_Name"] = "Please enter first name";
                let element = document.getElementById("Credit_Card_Holder_First_Name");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(!fields["Credit_Card_Holder_Last_Name"]){
                formIsValid = false;
                errors["Credit_Card_Holder_Last_Name"] = "Please enter last name";
                let element = document.getElementById("Credit_Card_Holder_Last_Name");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(!fields["Month_of_Expiry"]){
                formIsValid = false;
                errors["Month_of_Expiry"] = "Cannot be empty";
                let element = document.getElementById("Month_of_Expiry");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
            } 
    
         
            if(!fields["Year_of_Expiry"]){
                formIsValid = false;
                errors["Year_of_Expiry"] = "Cannot be empty";
                let element = document.getElementById("Year_of_Expiry");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                } 
    
                
                if(!fields["CVV"]){
                    formIsValid = false;
                    errors["CVV"] = "Cannot be empty";
                    let element = document.getElementById("CVV");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }  
                
                if(fields["CVV"] && !/^\d{3,4}$/.test(fields["CVV"])) {
                    formIsValid = false;
                    errors["CVV"] = "Invalid CVV code"; 
                    let element = document.getElementById("CVV");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});   
                    element.className = "form-control error"            
                }

        }
        
        
      


        // console.log('formIsValid --- ', formIsValid)

        this.setState({errors: errors});

        if(formIsValid){
            // this.setState({ loading: true });  
            const newList = this.state.formData;
            // console.log("newlist=>", newList);
            this.props.sendConsentData(newList, this.props.history);
            this.setState({loading:true})
        }

    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }

    handleFileRead = async (event) => {
        const file = event.target.files[0]
        let errors = {};
        let formIsValid = true;
        // console.log('file size > ',file.size/1024 )
        if(file  && file.type == "application/pdf"){

            if(file.size/1024 <= '500'){


            

            const localFile = await fetch(file);
            // then create a blob out of it (only works with RN 0.54 and above)
            const fileBlob = await localFile.blob();
           
            const base64 = await this.convertBase64(file)

           
            
            this.state.diseaseData['lab_report_file'] = base64;
            // this.state.formData['file'] =  a;
            event.target.className = "form-control" 
            // console.log('blob => ', base64)
            }else{
                formIsValid = false
                errors["lab_report_file"] = "File size too big. Please compress and upload under 500Kb";
                document.getElementById("lab_report_file").value = "";
                this.state.diseaseData['lab_report_file']="";
            }
        }
        else{
            formIsValid = false
            errors["lab_report_file"] = "Invalid file format.";
            this.state.diseaseData['lab_report_file']="";
            document.getElementById("lab_report_file").value = "";
            
        }

        // console .log('error => ', this.state.errors);
        this.setState({errors: errors});
       

    }

    onSubmitDisease = (e) => {              
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.diseaseData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;
        if(fields["submissionOption"] == "Waiver Submission"){
        if(!fields["Signature"]) {
            formIsValid = false;
            errors["Signature"] = "Please sign and click on check button.";
            // console.log('errors',errors);
            let element = document.getElementById("Signature");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }

        if(!fields["WaiverNotice"]) {
            formIsValid = false;
            errors["WaiverNotice"] = "Please Select.";
            // console.log('errors',errors);
            let element = document.getElementById("positive");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }

        
    }


        if(!fields["submissionOption"]){
            formIsValid = false;
            errors["submissionOption"] = "Please Select";
            let element = document.getElementById("submissionOption");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        

        if(!fields["agree"]){
            formIsValid = false;
            errors["agree"] = "Please Check ";
            let element = document.getElementById("agree");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            // element.className = "form-control error"
        }

        

        

        


        if(this.state.submission=='White Glove Labs'){

            
           

            if(!fields["Country"]){
                formIsValid = false;
                errors["Country"] = "Please enter Country";
                let element = document.getElementById("Country");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
            
            if(!fields["City"]){
                formIsValid = false;
                errors["City"] = "Please enter City";
                let element = document.getElementById("City");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
            if(!fields["State"]){
                formIsValid = false;
                errors["State"] = "Please enter State";
                let element = document.getElementById("State");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(!fields["Street"]){
                formIsValid = false;
                errors["Street"] = "Please enter Street";
                let element = document.getElementById("Street");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
           
            if(!fields["ZipCode"]){
                formIsValid = false;
                errors["ZipCode"] = "Please enter Zip Code";
                let element = document.getElementById("ZipCode");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(!fields["FirstName"]){
                formIsValid = false;
                errors["FirstName"] = "Please enter first name";
                let element = document.getElementById("FirstName");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
    
            if(!fields["LastName"]){
                formIsValid = false;
                errors["LastName"] = "Please enter last name";
                let element = document.getElementById("LastName");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
          

            if(this.state.update_card == true){

                if(!fields["Credit_Card_Number"]){
                    formIsValid = false;
                    errors["Credit_Card_Number"] = "Please enter credit card number";
                    let element = document.getElementById("Credit_Card_Number");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }
                
                if(fields["Credit_Card_Number"]) {
                    let cardvalid = valid.number(fields["Credit_Card_Number"]);
                    // console.log('cardvalid',cardvalid);
                    if(!cardvalid.isValid){
                        formIsValid = false;
                        errors["Credit_Card_Number"] = "Invalid credit card number.";
                        let element = document.getElementById("Credit_Card_Number");                
                        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                    }
                }
    
                if(!fields["CVV"]){
                    formIsValid = false;
                    errors["CVV"] = "Please enter CVV";
                    let element = document.getElementById("CVV");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }

                if(fields["CVV"] && !/^\d{3,4}$/.test(fields["CVV"])) {
                    formIsValid = false;
                    errors["CVV"] = "Invalid CVV code"; 
                    let element = document.getElementById("CVV");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});   
                    element.className = "form-control error"            
                }
    
                if(!fields["Credit_Card_Holder_First_Name"]){
                    formIsValid = false;
                    errors["Credit_Card_Holder_First_Name"] = "Please enter first name";
                    let element = document.getElementById("Credit_Card_Holder_First_Name");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }
    
                if(!fields["Credit_Card_Holder_Last_Name"]){
                    formIsValid = false;
                    errors["Credit_Card_Holder_Last_Name"] = "Please enter last name";
                    let element = document.getElementById("Credit_Card_Holder_Last_Name");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }

                if(!fields["Month_of_Expiry"]){
                    formIsValid = false;
                    errors["Month_of_Expiry"] = "Cannot be empty";
                    let element = document.getElementById("Month_of_Expiry");                
                        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({top: y, behavior: 'smooth'});
                        element.className = "form-control error"
                } 
        
                
    
                if(!fields["Year_of_Expiry"]){
                    formIsValid = false;
                    errors["Year_of_Expiry"] = "Cannot be empty";
                    let element = document.getElementById("Year_of_Expiry");                
                        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({top: y, behavior: 'smooth'});
                        element.className = "form-control error"
                    } 
        
                   
            }

            



        }

        if(this.state.submission=='Local Lab Submission'){

            if(!fields["lab_report_file"]){
                formIsValid = false;
                errors["lab_report_file"] = "Please select a pdf file";
                let element = document.getElementById("lab_report_file");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"              
            }
        }

        


        // console.log('formIsValid --- ', formIsValid)

        this.setState({errors: errors});
        
        if(formIsValid){
            // this.setState({ loading: true });  
            const newList = this.state.diseaseData;
            // console.log("newlist=>", newList);
            this.props.setDiseaseData(newList, this.props.history);
            this.setState({loading:true})
        }

    }

    


    render() {
        const {DoseData} = this.state
        const {formData} =this.state;
        // var data = []
      
           
        //    console.log("data 1 =>" ,data1)
        
       
        if(!sessionStorage.getItem('client_id') || sessionStorage.getItem('client_id')==null) {
           
            return <Redirect to="../" />
          }
        return (
            <MainLayout>
                <Header />

                { (this.state.loading)?
                <div class="loading-div">
                    <div class="loading-img"><img src="images/spinner.svg" alt="" /></div>
                </div>:""}

                <div className="main-content">
                    <Left activeMenu={"form-document"}/>  
                    <div class="right-section">
                        <div class="pagesubheader-wrap">
                        <div class="pagesubheader-row">
                            <div class="page-subheader-col-left">
                                <div class="title-avatar">
                                    <img src="images/physician-icon.svg" alt="" />
                                </div>
                                <div class="title-main">
                                    <span class="title-sub-text">Client Portal</span>
                                    <h1>Forms and Other Documents  </h1>
                                </div>
                            </div>
                        </div>
                        </div>

                        

                        <div class="inner-content-area">
                        {/* <div class="search-input col-md-6">
                            <i><img style={{marginLeft:"20px"}} src="images/icon_search.png" alt="" /></i>
                            <div> </div>
                            <input id="Search" style={{width:"200px"}} type="search" class="form-control" placeholder="Search Data..." onChange={this.onChange} />
                             <button style={{position:'absolute',top: '0px',left: "227px"}} className="btn btn-primary" onClick={this.searchData}> Search</button>                            
                             <button style={{position:'absolute',top: '0px',left: "308px"}} className="btn btn-secondary" onClick={this.Reset}> Refresh </button>                            
                             <span style={{color: "red"}}>{this.state.errors["Search"]}</span>
                             <br />
                        </div> */}
                        <br />
                        <div class="patient-details-tabs">
                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li class="nav-item">
                                    <a class={'nav-link ' +this.state.tab1} onClick={this.tabChange} value="consent_form" id="pills-related-tab" data-toggle="pill" href="#pills-related" role="tab" aria-controls="pills-related" aria-selected="false">Client Consent Form</a>
                                </li>
                            <li class="nav-item">
                                <a class={'nav-link ' +this.state.tab2} onClick={this.tabChange} value="disease_form" id="pills-details-tab" data-toggle="pill" href="#pills-details" role="tab" aria-controls="pills-details" aria-selected="true">Infectious Disease Form</a>
                            </li>
                            
                        </ul> 
                            <div class="tab-content" id="pills-tabContent">
                            {/* <div class="tab-pane fade " id="pills-related" role="tabpanel" aria-labelledby="pills-related-tab"> */}
                            <div  class={'tab-pane fade '+ this.state.tab1} id="pills-related" role="tabpanel" aria-labelledby="pills-related-tab">
                            
                         
                            {( this.state.clientConsentFormCompleted == "true")?
                            <div>
                            <h5 style={{paddingTop:"10px"}}><i>You have already completed this form. Thank you</i></h5>
                        </div>:
                        <div>
                        {(this.state.initialProcessingFormCompleted == "false") ? 
                        <div>
                             <h5 style={{paddingTop:"10px"}}><i>Please complete Initial Processing payment to proceed with consent form.</i></h5>
                        </div>: 
                            <div className="inner-content-area"> 
                    {(this.state.payment_response)?
                        <div>
                             <h5 style={{paddingTop:"10px"}}><i>Please check your email for order confirmation. We are now getting the portal services ready for you!</i></h5>
                        </div>:

                    <div className="form-wrap glove-form">
                    
                    <form className="needs-validation" noValidate onSubmit={this.onSubmit}>
                    {(this.state.page=="1")?  
                    <div className="row">
                        <div className="col-md-12">
                        <p>This Stem Cell Processing and Storage Agreement (this “Agreement”) is between the Client signing below (“you” or the “Client”) and American Cell Technology, LLC., a Florida Limited Liability Company ("Bank") and is entered into today (the "Enrollment Date") for the screening, processing, expanding, freezing, storing, withdrawing, transferring, donating, shipping, transporting and/or other use of your adipose (fat), muscle, bone marrow tissue, and/or umbilical cord or umbilical tissue or other related maternal tissues that is collected by your physician (the "Tissue"), for the purpose of extracting and storing the mesenchymal stem cells and associated extracellular products (the "Stem Cells") contained in the Tissue, on the terms set forth in this Agreement. This Agreement legally binds Bank and the Client.</p>
                        </div>

                        <div className="col-md-12">
                            <h4>Part l</h4>
                            <h5>INFORMED CONSENT</h5>
                            <p>
                            By signing below, you acknowledge that you have the legal and mental capacity to sign this document and that you have given your informed consent to your physician to undergo liposuction or another tissue extraction procedure, and that you also have given your consent to the collection of your Tissue by your physician during such procedure. You further acknowledge that your consent includes, but it not limited to: the performance of such procedures as may be necessary or appropriate to transport, screen, process and process your Tissue after removal by your physician, as well as the extraction of the Stem Cells, if any, that may be present in the Tissue, followed by the cryopreservation of the Stem Cells. You also acknowledge that you consent to the terms and conditions relating to the transportation, screening, processing, expansion, cryopreservation, storage, withdrawal, transfer, donation, shipping and/or other use of the Tissue and the Stem Cells, by or on behalf of American Cell Technology, LLC ("Bank"), that are set forth in this Agreement. You acknowledge that you have had infectious Disease testing as directed prior to or concurrent with the tissue harvesting procedure. In some cases where it wasn't possible to have the Infectious Disease testing done prior to collection, then you agree to have it done within the next three weeks with these results provided to the Bank.
                            </p>

                            <ol>
                                <li>
                                Risks Involved in Storage and Use. You understand that the cryopreservation of Stem Cells from the Tissue is a relatively new procedure, and that there are laboratory test and other studies that have indicated that it is a successful method of preserving your own stem cells. You understand that the transplantation of Stem Cells is relatively new procedure that may offer possible future benefits to you in treating certain conditions. You also understand that there are no assurances, warranties or guarantees that any such benefits will be obtained.
                                </li>
                                <li>
                                Collection Risks and Consent. You have been fully informed about the procedure for collecting the Tissue and hereby authorize and direct your physician, his/her employees and/or contracted health care provider(s) to collect your Tissue during your schedule procedure, for packaging, transport and delivery to Bank, using a collection set ("Collection Set") provided to your physician by Bank. You understand that although the collection of the Tissue is a relatively simple procedure, complication may occur during the procedure that could preclude the collection of the Tissue. You acknowledge that you have been fully informed of, accept and agree to the conditions, risks, limitations and costs of collecting, processing, testing and storing of your Tissue, which include, without limitation, the following:
                                </li>
                            
                            <ul>
                                <li>
                                a risk of contamination when collecting the Tissue;
                                </li>
                                <li>there is no guarantee or assurance of the success of the collection procedure;</li>
                                <li>the Tissue collected may be insufficient for stem cell extraction or any other purpose;</li>
                                <li>there may be complications that will make it impossible or problematic to collect the Tissue, and your physician may properly refuse to collect the Tissue; and</li>
                                <li>the Tissue that is collected may not be acceptable for any medical use as determined by your physician at the time of collection, or by Bank upon receipt of the Tissue. </li>
                                
                            </ul>
                            <p>Therefore, you agree that your physician may determine in the exercise of his/her professional medical judgement, whether or not to proceed with collecting the Tissue during the procedure, and that the determination of your physician shall be final and binding on you. Nonetheless, being cognizant of the forgoing conditions, risks and limitations, you hereby consent to allow your physician to collect the Tissue and to furnish it to Bank for testing, processing, cryopreserving, storing, and servicing.</p>
                            <li> Disclosure of Health Information. Bank will use reasonable procedures to safeguard the confidentiality of health information that you provide to Bank concerning your health. You hereby consent to the use of your confidential health information, including the blood and Tissue testing results, by Bank, and acknowledge and agree that Bank may retain and make further use of such confidential health information for all lawful purposes, as may be reasonably necessary for Bank's performance of its obligations under this Agreement or as otherwise may be permitted or required by applicable law or regulations.</li>
                            <li>Release from Liability. You hereby for yourself, your agents, attorneys, successors-in-interest, affiliates, representatives, heirs and assigns, irrevocably and unconditionally release and discharge Bank and its shareholders, directors, officers, employees, agents, representatives, affiliates, and/or their respective legal representatives, estates, successors and assigns, from and against any and all claims, causes of action, liabilities, damages, costs, and expenses, known and unknown, that you, or your heirs, assigns, or representatives may incur, resulting from or relating to the processing (after collection by your physician), screening, expanding, freezing, storing, withdrawing, transferring, donating, shipping, transporting and/or other use of the Tissue and Stem Cells pursuant to the Agreement. Without limiting the foregoing, you further acknowledge that Bank is not responsible in any way for the actions of others including your physician, medical facility, staff of the medical facility, laboratory staff, and transporters of the Tissue. You understand that by agreeing to this Release from Liability you are giving up rights that you might otherwise have now or in the future to seek money damages or other remedies or relief from Bank, its affiliates, its agents or managers, and other persons and entities referenced in this Release from Liability.</li>
                            </ol>
                        
                        </div>
                        
                        <div className="col-md-12">
                            <h4>PART ll</h4>
                            <h5>CLIENT SERVICE AGREEMENT TERMS AND CONDITIONS</h5>

                            <ol>
                                <li>Screening, Processing, Expanding, Freezing, and Storing the Tissue.
                                Upon receipt of the Tissue, Bank will screen and process the Tissue, and will attempt to extract, expand, cryopreserve and store the Stem Cells contained in the Tissue. Bank will perform a visual inspection and conduct a cell viability test to determine the suitability of the Tissue for Stem Cell extraction, expansion, cryopreservation and storage. Stem Cell extraction, expansion, cryopreservation, and cryo-storage are collectively referred to as the "Tissue Banking Services". Once the Tissue is deemed acceptable by Bank, Bank will process the Tissue, extract the stem cells, expand the stem cells, and/or store the stem cells in a cryogenic cellular storage unit until the termination of this Agreement in accordance with the terms set forth herein. Bank's obligations are expressly limited to the services described in this Agreement.</li>
                                <li>Client Representations and Warranties. You represent and warrant that:
                                    <p>a. You are legal age or you are the legal custodian or have power of attorney for the Client;</p>
                                    <p>b. You have carefully read and understand all of the terms of this Agreement;</p>
                                    <p>c. Your decision to have the tissue collected, processed, expanded and stored is a completely voluntary act;</p>
                                    <p>d. You have had the opportunity to discuss this Agreement with a competent medical professional, who is not an employee or representative of Bank; and</p>
                                    <p>e. You understand the risks related to collection, expansion, storage and the possible future use of the Tissue.</p>
                                    </li>
                                    <li>Use and Ownership of Tissue.
                                    <p>a. Client acknowledges and agrees that the Tissue and the Stem Cells derived therefrom are Personal Cells. These cells are surgically isolated from your body and then replicated as stem cells (Mesenchymal Stem Cells or MSCs) in the laboratory under sterile conditions so that all of these cells remain your own DNA and are otherwise unaltered genetically or chemically. Cells removed from your own body, even if replicated, and for your own use are considered "Autologous". These cells are naturally occurring and cannot be patented. They are owned by you and they cannot be sold to any other persons. They are for your personal use only.</p>
                                    <p>b. Bank will receive and retain possession of the Tissue and the Stem Cells derived therefrom in accordance with the terms of this Agreement, until this Agreement is terminated in accordance with Section 6 hereof.</p>
                                    <p>c. Upon termination of this Agreement, unless the Client arranges for transfer or the taking of possession of their Stem Cells through financial arrangements with the Bank, Bank will dispose of the Tissue and/or the Stem Cells derived therefrom, in the manner set forth in Section 7 hereof.</p>
                                   <p> d. Notwithstanding Bank's possession and custodianship of the Tissue and the Stem Cells derived therefrom, Client is and shall remain the owner of the Tissue and the Stem Cells derived therefrom, until the release or disposition of same by Bank.</p>
                                    </li>
                                <li>Requests for Release of Stem Cells. Commencing three (3) months after the Enrollment Date, Client or Client's Physician acting on behalf of the Client may direct Bank to release and transfer the Stem Cells derived from Client's Tissue, by providing at least two (3) weeks' advance written notice to Bank. The request must be made on a request form provided by Bank (a "Retrieval Request Form"), must be signed by the Client or the Client's Physician requesting on behalf of the Client, must include the name and address of the recipient to whom the stem cells are to be delivered and the date delivery is requested. By signing this Client Consent form, the Client gives permission to their associated Physician to request cells for the Client on their behalf unless notified otherwise. The transfer or disposal of the Stem Cells will be performed in accordance with Bank's standard operating procedures in effect at the time of the request. Client is required to pay all administrative, handling, transportation and delivery charges for the release and transfer of the Stem Cells prior to shipment thereof, and Bank shall not be required to release, prepare, ship, or dispose of the Stem Cells unless and until any and all payments required to be made under this Agreement have been paid in full.</li>
                                <li>Fees. Client agrees to pay the fees (the "Fees") set forth below for the services selected by Client. Fees must be paid by the due dates. Any taxes that may be due on the Fees are the responsibility of Client, and the Fees are expressed exclusive of taxes. Bank reserves the right in its discretion, to make adjustments from time to time to the Initial Fee, Annual Maintenance Fee, and the Retrieval Fee (as defined below). Bank will notify Client in writing of any such change in the Fees. If Client fails to pay the Fees when due, Client's account may be forwarded to a collection agency for further action. Client consents to receive autodialed and/or pre-recorded calls from or on behalf of Bank for account collection purposes at the landline and/or mobile telephone numbers provided to Bank. If Client fails to pay any Fee when due or otherwise fails to comply with the terms and conditions of this Agreement, Bank shall not be obligated to provide, or continue to provide, the Tissue Banking Services or any other services hereunder. The Fees for the Tissue Banking Services and other services provided by Bank are as follows:
                                <p>a. An initial fee for the Tissue Banking Services including one year of storage of the stem cells ("Initial Fee"), payable in full in advance of the receipt of the Tissue by Bank;</p>
                                <p>b. An annual fee for the storage of the stem cells during each year, or portion of a year, after the end of the first year of the term of this Agreement ("Annual Maintenance Fee"), in the amount of $250.00 per year, payable in full before each anniversary of the Enrollment Date; and</p>
                                <p>c. A fee for the retrieval and transfer of a single dose of stem cells ("Retrieval Fee"), in the amount of $500.00 for each transfer of a single vial of stem cells or associated extracellular products.</p>
                                </li>
                                <li>Term and Termination. The terms of this agreement shall commence on the Enrollment Date, and shall continue until terminated as follows:
                                <p>a. Termination by Bank. This Agreement may be terminated by Bank at any time by giving Client sixty (60) days' advance written notice. In the event this Agreement is terminated by Bank pursuant to this Section 6(a) prior to the end of a yearly period for which an Annual Fee has been paid by Client, Bank shall not refund to Client the pro-rata portion of the Annual Fee for the unused portion of the year after the date of termination, and Client shall be entitled to retrieve the stem cells by submitting a Retrieval Request Form to Bank prior to the expiration of the sixty (60) day notice period.</p>
                                <p>b. Termination by Client. This Agreement may be terminated by Client at any time by delivering a written notice of termination to Bank. In the even this Agreement is terminated by Client pursuant to this Section 6(b), Client shall not be entitled to receive a refund of any part of any Fees paid by Client.</p>
                                <p>c. Termination upon Death of Client. This Agreement will terminate immediately upon the death of Client. If such termination occurs prior to the end of a yearly period for which an Annual Fee has been paid by Client, Client's estate of heirs shall not be entitled to receive a refund of the pro-rata portion of the Annual Fee for the unused portion of the year from the date of termination. Upon Client's death, Bank shall dispose of any Tissue and/or the Stem Cell derived from the Tissue of Client in Bank's possession as directed by Client in Addendum A hereto. </p>
                                <p>d. Termination due to a Force Majeure Event. This Agreement may be terminated by Bank upon the loss, deterioration or destruction of all or any part of the Tissue and/or the Stem Cells derived from the Tissue of Client, from causes or circumstances beyond Bank's reasonable control, including, without limitation, fires, explosions, or power outages, natural disasters, disruption of utilities or public services, strikes or labor shortages, terrorist acts, or acts of war, acts of governments, or any law, proclamation, ordinance, demand, or requirement of any government agency. In the event this Agreement is terminated by Bank pursuant to this Section 6(d), Client shall not be entitled to receive a refund of any part of any Fees paid by Client.</p>
                                <p>e. Termination for Non-Payment of Fees. Bank may terminate this Agreement in the event Client fails to pay when due any Fee payable hereunder. If this Agreement is terminated for non-payment, Client shall not be entitled to receive a refund of any part of any Fees paid by Client.
                                have the right to terminate this Agreement, and the Client shall not be entitled to receive a refund of any part of any Fees paid by Client.</p>
                                <p>g. Automatic Termination. If not otherwise terminated pursuant to Section 6(a) through Section 6(f) above, this Agreement will automatically terminate at such time as no Tissue and/or Stem Cell derived from Tissue collected from Client, remains in the possession of bank, whether due to the prior retrieval, transfer, or permitted disposal of the Tissue and/or the Stem Cells derived from the Tissue of Client. If the automatic termination occurs prior to the end of a yearly period for which an Annual Fee has been paid by Client, Client shall not be entitled to receive a refund of the pro-rata portion of the Annual Fee for the unused portion of the year from the date of termination.</p>
                                </li>
                                                                <li> Disposal of Tissue and/or Stem Cells. Bank will be entitled to dispose of the Tissue and/or the Stem Cells derived from the Tissue in accordance with standard clinical practices and without further notice to the Client, as follows:
                                <p>a. upon termination of this Agreement for any reason; and</p>
                                <p>b. whenever there is excess material remaining following the completion of processing of the Tissue.</p>
                                Such disposal may consist of disposal as medical waste, donation for academic or research purposes, or any other proper purpose as determined by Bank, in its sole discretion.</li>
                                                                <li>Communications. From time to time, Bank may send notices and updated to Client via U.S. mail or e-mail, which may include marketing communications. Client consents to electronic mail correspondence and other communications, including for marketing purposes.</li>
                                                                <li>Indemnification. Client agrees to indemnify, defend and hold harmless bank and its affiliates, including without limitation their respective shareholders, directors, officers, employees, agents (including marketing agents) and/or other representatives, from and against any and all claims, liabilities, losses, costs and expenses (including attorney's fees and costs of defense), damages, settlements, and judgements arising out of or related to:
                                <p>a. the provision of, or failure to provide, services by Bank pursuant to this Agreement; and</p>
                                <p>b. claims concerning rights in and to the Tissue or the Stem Cells extracted therefrom, the transportation thereof, and the disposition of same. 
                                It is well known that there are regulatory agencies that may cause such disruptions of services for which the Bank cannot be held responsible. Should such disruptions occur the Bank will pursue efforts to rectify such situations however feasible to the extent legally possible. Current regulatory control oversees prevention of transmission of communicable disease to which the Bank takes all necessary steps for such prevention. Further, regulatory control oversees interstate commerce especially among drug or biological products. The Bank remains the conservator and custodian of your stem cells and stem cell products unless this agreement is violated by failure to pay for Bank services.</p>
                                </li>
                                                                <li>No Warranty. Client acknowledges that neither Bank nor any of its officers, directors, shareholders, executives, employees, agents (including without limitation marketing agents), representatives, consultants, and/or affiliates has made, nor makes herein, any representations or warranties to Client, express or implied, of any kind or nature, including, without limiting the generality of the foregoing, any representations or warranties with respect to:
                                <p>(i) suitability of Stem Cells for future treatment of diseases;</p>
                                <p>(ii) successful treatment of diseases through stem cell transplantation;</p>
                                <p>(iii) advantages of stem cell transplantation over other types of treatment; or</p>
                                <p>(iv) successful preservation of stem cells through cryopreservation.</p>
                                <p>Client acknowledges and agrees that neither Bank nor any of its shareholders, directors, officers, agents, employees or affiliates have made any representation, guaranty or warranty, express or implied, to Client.</p>
                                </li>
                                <li>Limitation of Liability. CLIENT HEREBY ACKNOWLEDGES AND AGREES THAT BANK HAS NO LIABILITY OF ANY KIND IN RESPECT OF BANK'S PERFORMANCE OR FAILURE TO PERFORM UNDER THIS AGREEMENT EXCEPT TO THE EXTENT ATTRIBUTABLE TO BANK'S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT. IN NO EVENT SHALL BANK'S LIABILITY EXCEED THE TOTAL AMOUNT PAID BY CLIENT TO BANK UNDER THIS AGREEMENT. BANK SHALL NOT BE LIABLE FOR ANY SPECIAL, INDIRECT, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES IN RESPECT OF BREACH OF CONTRACT, WARRANT, STRICT LIABILITY OR TORT), WHETHER OR NOT BANK HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE LIMITATIONS SET FORTH IN THIS SECTION SHALL APPLY NOTWITHSTANDING THE FAILURE OF THE ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.</li>
                                <li>Assignment and Delegation. Bank may assign its rights and delegate its obligations under this Agreement at any time and in its sole discretion, without further consent of Client. Bank may perform services to be provided by it hereunder through subcontractors, agents or managers pursuant to management services agreements. This Agreement is not assignable by Client without the prior written consent of bank and any attempted assignment in breach of the foregoing restriction shall be void.</li>
                                <li>Governing Law, Jurisdiction and Venue; Attorney's Fees. This Agreement is governed by the laws of the State of Florida without regard to its conflict of laws principles. Any judicial proceedings brought against either Bank or Client under this Agreement will be brought in a court in Miami-Dade County, Florida. Bank and Client consent to the exclusive jurisdiction of the aforesaid courts and waive any objection to venue therein and irrevocably agree to be bound by any judgement rendered thereby. The prevailing party in any such proceedings will be entitled to an award of its reasonable attorney's fees, paralegal fees, costs and expenses incurred in such proceedings.</li>
                                <li>Entire Agreement. This Agreement constitutes the entire Agreement between parties and supersedes all previous agreements or representations by Bank or Client, oral or written, relating to the subject matter of this Agreement.</li>
                                <li>Severability. If any provision of this Agreement is held by court of competent jurisdiction to be invalid, void, or unenforceable, the remaining provisions shall nevertheless continue in full force without being impaired or invalidated in any way.</li>
                                <li>Survival. All provisions that by their terms require performance after the termination of this Agreement will survive the termination of this Agreement.</li>
                                <li>Headings. The headings in this Agreement are for reference purposes only and shall not affect the meaning or interpretation of this Agreement.</li>
                                <li>Notices. Any notice to be given hereunder by either party to the other shall be in English and in writing, and shall be hand delivered or sent by overnight courier or registered, express mail to the addresses listed on the enrollment documents (or any updated address provided by Client in writing to Bank or by Bank in writing to Client, and shall be deemed to be effective two (2) business days after dispatch). Or, in the event of using electronic mail, notice may also be given and accepted if verified that it was received by demonstration of a suitable electronic signatory response.</li>
                                <li> Amendment. Except as set forth herein, this Agreement may only be modified or amended by a writing signed by each party and shall be binding on Client and Bank and their heirs, personal representatives, successors and permitted assigns.
                                        <br />    By signing below, you confirm that you have read and fully understand the terms and conditions of this Agreement, including both Part I: Informed Consent and Part II: Client Service Agreement terms and Conditions.
                                            </li>   
                            </ol>
                        </div>
                        <div className="col-md-12">
                        <h2 className="title-2">ADDENDUM A</h2>
                        <div class="submission-detail-sec">
                        <div class="row"> 
                        <div className="col-md-12">
                            <p>In the event of my death, I direct Bank to:</p>
                            <div class="choose-options-sec">
                                <div class="custom-control custom-radio">
                                <input type="radio" id="Tissue_deliver_1" name="Tissue_deliver" class="custom-control-input" value="Destroy any Tissue" onChange={this.checkRadio1} />
                                <label class="custom-control-label" for="Tissue_deliver_1">Destroy any Tissue and/or the Stem Cells derived from my Tissue in Bank’s possession. I understand that Bank will retain possession of my Tissue and/or Stem Cells derived from my Tissue for 120 days after my death, in case of legal claims by my estate or heirs, after which Bank will destroy my Tissue and/or Stem Cells derived from my Tissue.</label>
                                </div>
                                <div class="custom-control custom-radio">
                                <input type="radio" id="Tissue_deliver_2" name="Tissue_deliver" class="custom-control-input" value="Release any Tissue to the following individual" onChange={this.checkRadio1} />
                                <label class="custom-control-label" for="Tissue_deliver_2">Release any Tissue and/or the Stem Cells derived from my Tissue in Bank’s possession to the following individual</label>
                                </div>
                            </div>                            
                        </div>
                        
                        {(this.state.Tissue_deliver_Box)?
                        <div className="row">
                        <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Name <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="Addendum_name" className="form-control" placeholder="" onChange={this.onChange} value={formData.Addendum_name} />
                                    <span style={{color: "red"}}>{this.state.errors["Addendum_name"]}</span>
                                </div>
                                </div>
                        </div>
                        <div className="col-md-6">
                                 <div className="form-group">
                                 <label className="col-form-label">Individual Birth Date <span className="required">*</span></label>
                                 <div className="field-col">
                                 <DatePicker id="Addendum_dob" className="form-control bggray"  placeholderText="mm/dd/yyyy" selected={this.state.Addendum_dob}  onChange={date => this.datechange3(date)} /><br />
                                     {/* <input type="text" id="Addendum_dob" className="form-control" placeholder="" onChange={this.onChange} /> */}
                                    <span style={{color: "red"}}>{this.state.errors["Addendum_dob"]}</span>
                                 </div>
                                 </div>
                         </div>                       
                        <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Address <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="Addendum_address" className="form-control" placeholder="" onChange={this.onChange} value={formData.Addendum_address} />
                                    <span style={{color: "red"}}>{this.state.errors["Addendum_address"]}</span>
                                </div>
                                </div>
                        </div>
                        <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Phone <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="Addendum_phone" className="form-control" placeholder="" onChange={this.onChange} value={formData.Addendum_phone} />
                                    <span style={{color: "red"}}>{this.state.errors["Addendum_phone"]}</span>
                                </div>
                                </div>
                        </div>

                        <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Relationship <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="Relationship" className="form-control" placeholder="" onChange={this.onChange} value={formData.Relationship} />
                                    <span style={{color: "red"}}>{this.state.errors["Relationship"]}</span>
                                </div>
                                </div>
                        </div>
                        </div>
                        :""}
                        <div className="col-md-12">
                        <div class="choose-options-sec">
                                <div class="custom-control custom-radio">
                                <input type="radio" id="Tissue_deliver_3" name="Tissue_deliver" class="custom-control-input" value="Release any Tissue to Bank of Letters Testamentary" onChange={this.checkRadio1} />
                                <label class="custom-control-label" for="Tissue_deliver_3"> Release any Tissue and/or the Stem Cells derived from my Tissue in ACT’s possession to the Executor or Administrator of my estate upon presentation to Bank of Letters Testamentary  </label>
                                </div>
                                <span style={{color: "red"}}>{this.state.errors["Tissue_deliver"]}</span>
                        </div>                        
                        </div>
                        </div>
                        </div>
                        <h2 className="title-2">Current Medications</h2>
                        <h3 class="title-3 mb-3">Regarding the following medications:
                        Statin drugs, NSAIDs (including: Aspirin, Advil, Motrin, Aleve, Voltaren, Mobic, Celebrex), Tylenol (Acetaminophen), or any Steroids
                        </h3>
                        <h3 class="title-3 mb-3">Are you currently taking any of the medications listed above?</h3>
                        <div class="choose-options-sec">
                            <div class="custom-control custom-radio">
                                <input type="radio" id="Medications_No" name="Medications" class="custom-control-input" value="No" onChange={this.checkRadio} />
                                <label class="custom-control-label" for="Medications_No">No</label>
                                </div>
                                <div class="custom-control custom-radio">
                                <input type="radio" id="Medications_Yes" name="Medications" class="custom-control-input" value="Yes" onChange={this.checkRadio} />
                                <label class="custom-control-label" for="Medications_Yes">Yes</label>
                                </div>
                                <span style={{color: "red"}}>{this.state.errors["Medications"]}</span>    
                        <br /> 
                        </div>
                        { (this.state.Medications=="yes")?
                        <div className="col-md-6">
                        <div className="form-group">
                        <label>Medications</label>
                        <input type="text" id="Medications_taking" className="form-control" placeholder onChange={this.onChange} />
                        </div>
                        </div>:""}
                        </div>
                        <div className="col-md-6">
                        <button type="button" id="2" onClick={this.setcount} className="btn btn-outline-primary mr-2">Next Page</button>
                        </div>
                        </div>:
                         <div className="col-md-12 submission-option-detail">
                         <span style={{color: "red"}}>{this.state.api_error}</span>
                             <br />
                             <h2 className="title-2">Client Information</h2>
                             <div className="submission-detail-sec">
                             <div className="row">
                             <div className="col-md-6">
                             <div className="form-group">
                             <label className="col-form-label">Client ID</label>
                             <div className="field-col">
                                 <input type="text" className="form-control" placeholder="" value={this.state.formData['ClientId']} disabled />
                             </div>
                             </div>
                             </div>
                             <div className="col-md-6">
                             <div className="form-group">
                             <label className="col-form-label">Client Last Name</label>
                             <div className="field-col">
                             <input type="text" className="form-control" placeholder="" value={this.state.formData['ClientLastName']} disabled />
                             </div>
                             </div>
                             </div>
                             </div>
                             </div>
                         <br />
                            
                        
                             
                             {/* <h2 className="title-2">Client Information</h2> */}                             
                             
     
                             <h2 className="title-2">Tissue  Information</h2>
                             <h3 class="title-3 mb-3">Select Sample Type</h3>
                             <div class="choose-options-sec">
                                 <div class="custom-control custom-radio">
                                     <input type="radio" id="Adipose" name="sample_type" value="Adipose" class="custom-control-input" onChange={this.checkbtn} />
                                     <label class="custom-control-label" for="Adipose">Adipose</label>
                                     </div>
                                     <div class="custom-control custom-radio">
                                     <input type="radio" id="Bone-Marrow" name="sample_type" value="Bone-Marrow" class="custom-control-input" onChange={this.checkbtn} />
                                     <label class="custom-control-label" for="Bone-Marrow">Bone Marrow</label>
                                     </div>
                                     <span style={{color: "red"}}>{this.state.errors["sample_type"]}</span> 
                             <br /> 
                             </div>
     
                             <div className="mid-sections">
                                         <h2 className="title-2 mb-4">Annual Storage Fee Commitment</h2>
                                         <div className="payment-info">
                                         <div className="card">
                                             <div className="card-header">
                                             <h4>Yearly Banking Fee Credit Card Information </h4>
                                             <div className="custom-control custom-checkbox">
                                                 <input type="checkbox" className="custom-control-input" id="customCheck2" defaultChecked />
                                                 <label className="custom-control-label" htmlFor="customCheck2" />
                                             </div>
                                             </div>
                                             <div className="card-body">
                                             <p>The card below will be charged on an annual basis starting 1 year from receipt of your cells. The first year of storage is included with your initial processing. </p>
                                             
                                             <div className="saved-card-detail">
                                                 <div className="row">
                                                 <div className="col-md-6">
                                                     <label>Card Number</label>
                                                     <div className="card-dtl-value">

                                                     {(this.state.card_info['Credit_Card_Type'] == 'Mastercard') ?<img class="card-logo" src="images/master-card.png" alt="" /> :""}
                                                {(this.state.card_info['Credit_Card_Type'] == 'American Express') ?<img class="card-logo" src="images/card-3.svg" alt="" /> :""}
                                                {(this.state.card_info['Credit_Card_Type'] == 'Discover') ?<img class="card-logo" src="images/card-4.svg" alt="" /> :""}
                                                {(this.state.card_info['Credit_Card_Type'] == 'Visa') ?<img class="card-logo" src="images/card-1.svg" alt="" /> :""}
                
                                                     {/* <img className="card-logo" src="images/master-card.png" alt="" /> */}
                                                     <span>Credit Card ending in {this.state.card_last_gid}</span>
                                                     </div>
                                                 </div>
                                                 <div className="col-md-3">
                                                     <label>Name on card</label>
                                                     <div className="card-dtl-value">
                                                     <span>{this.state.card_info['Credit_Card_Holder_First_Name']} {this.state.card_info['Credit_Card_Holder_Last_Name']}</span>
                                                     </div>
                                                 </div>
                                                 <div className="col-md-3">
                                                     <label>Expiry</label>
                                                     <div className="card-dtl-value">
                                                     <span>{this.state.card_info['Month_of_Expiry']} / {this.state.card_info['Year_of_Expiry']}</span>
                                                     </div>
                                                 </div>
                                                 </div>
                                             </div>
                                             <br />
                                             <div className="custom-control custom-checkbox">
                                                 <input type="checkbox" id="update_card" name="update_card" className="custom-control-input" value="true" onClick={this.checkRadio3}/>
                                                 <label className="custom-control-label" for="update_card">Use Another Credit Card </label>
                                               
                                             </div>
     
                                             {(this.state.update_card == true)?
                                             <div className="card-edit-option">
                                                 <div className="card-detail-form">
                                                 <div className="card-detail-wrap mb-5">
                                                     <h5>Credit Card Detail <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title alt="" data-original-title="Tooltip Text" /></h5>
                                                     <div className="row">
                                                     <div className="col-md-6">
                                                         <div className="form-group">
                                                         <label>Credit Card Number <span className="required">*</span> </label>
                                                         <input type="text" id="Credit_Card_Number" className="form-control"  onChange={this.onChange} />
                                                         <span style={{color: "red"}}>{this.state.errors["Credit_Card_Number"]}</span>
                                                         </div>
                                                     </div>
                                                     <div className="col-md-6">
                                                         <div className="form-group">
                                                         <label>CVV <span className="required">*</span> </label>
                                                         <input type="password" id="CVV" className="form-control" placeholder="***" onChange={this.onChange} />
                                                         <span style={{color: "red"}}>{this.state.errors["CVV"]}</span>
                                                         </div>
                                                     </div>
                                                     {/* <div className="col-md-3">
                                                         <div className="form-group">
                                                         <label>Expiry Date <span className="required">*</span> </label>
                                                         <input type="text" className="form-control" placeholder="-- --  /  -- -- -- --" />
                                                         <span style={{color: "red"}}>{this.state.errors["CreditCard"]}</span>
                                                         </div>
                                                     </div> */}
     
                                                 <div className="col-md-6 mb-3">
                                                 <label htmlFor="Month_of_Expiry">Card Expiration Date <span className="act_required">*</span></label>
                                                 <select className="custom-select d-block w-100" id="Month_of_Expiry" onChange={this.onChange}  placeholder="mm" >
                                                     <option value="">Select Month</option>
                                                     <option value="01">01</option>
                                                     <option value="02">02</option> 
                                                     <option value="03">03</option> 
                                                     <option value="04">04</option> 
                                                     <option value="05">05</option>    
                                                     <option value="06">06</option> 
                                                     <option value="07">07</option> 
                                                     <option value="08">08</option> 
                                                     <option value="09">09</option> 
                                                     <option value="10">10</option> 
                                                     <option value="11">11</option>
                                                     <option value="12">12</option>  
                                                 </select>
                                                 {/* <input type="text" className="form-control" id="Month_of_Expiry" onChange={this.onChange} placeholder="mm" required /> */}
                                                 <span style={{color: "red"}}>{this.state.errors["Month_of_Expiry"]}</span>
                                                 <div className="invalid-feedback">
                                                 Valid Month required
                                                 </div>
                                                 </div>
     
                                                     <div className="col-md-6 mb-3">
                                                     <label htmlFor="creditCardExpirationYear"> &nbsp;</label>
                                                     <select className="custom-select d-block w-100" id="Year_of_Expiry" onChange={this.onChange}  placeholder="YYYY" >
                                                     <option value="">Year</option>
                                                  
                                                     <option value="2025">2025</option>
                                                     <option value="2026">2026</option>
                                                     <option value="2027">2027</option>
                                                     <option value="2028">2028</option>
                                                     <option value="2029">2029</option>
                                                     <option value="2030">2030</option>
                                                     <option value="2031">2031</option>
                                                     <option value="2032">2032</option>
                                                     </select>
                                                     {/* <input type="text" className="form-control" id="creditCardExpirationYear" onChange={this.onChange} placeholder="yy" required /> */}
                                                     
                                                     <span style={{color: "red"}}>{this.state.errors["Year_of_Expiry"]}</span><div className="invalid-feedback">
                                                     Valid Year required
                                                     </div>
                                                     </div>
     
                                                     
                                                     <div className="col-md-12">
                                                         <div className="card-logo-list">
                                                         <div><img src="images/card-1.svg" alt="" /></div>
                                                         <div><img src="images/card-2.svg" alt="" /></div>
                                                         <div><img src="images/card-3.svg" alt="" /></div>
                                                         <div><img src="images/card-4.svg" alt="" /></div>
                                                         </div>
                                                     </div>
                                                     </div>

                                                     
                                                 </div>
                                                 <div className="col-md-12">                                            
                                                         <div className="custom-control custom-checkbox">                                                    
                                                         <input type="checkbox" id="save_card" name="save_card" className="custom-control-input" value="true" onClick={this.savecard}/>
                                                         <label className="custom-control-label" for="save_card">Save this credit card on file for future payments</label>
                                                     
                                                         </div>
                                                 </div>
     
                                                 <div className="card-detail-wrap">
                                                     <h5>Billing Address <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title alt="" data-original-title="Tooltip Text" /></h5>
                                                     <div className="row">
                                                     <div className="col-md-6">
                                                         <div className="form-group">
                                                         <label>First Name</label>
                                                         <input type="text" id="Credit_Card_Holder_First_Name" className="form-control" placeholder onChange={this.onChange} />
                                                         <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_First_Name"]}</span>
                                                         </div>
                                                     </div>
                                                     <div className="col-md-6">
                                                         <div className="form-group">
                                                         <label>Last Name</label>
                                                         <input type="text" id="Credit_Card_Holder_Last_Name" className="form-control" placeholder onChange={this.onChange} />
                                                         <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_Last_Name"]}</span>
                                                         </div>
                                                     </div>
                                                     <div className="col-md-6">
                                                         <div className="form-group">
                                                         <label>Country</label>
                                                         <input type="text" id="Credit_Card_Holder_Country" className="form-control" placeholder onChange={this.onChange} />
                                                         </div>
                                                     </div>
                                                     <div className="col-md-6">
                                                         <div className="form-group">
                                                         <label>Zip/Postal Code</label>
                                                         <input type="text" id="Credit_Card_Holder_Zip"  className="form-control" placeholder onChange={this.onChange} />
                                                         </div>
                                                     </div>
                                                     </div>
                                                 </div>
                                                 </div>
                                             </div>
                                             :""}
                                             <div className="act-total">
                                                 <p>Total Owed Today: $0.00</p>
                                             <p>Annual Storage Fee After First Year: $250.00 </p>
                                             </div>
                                             <p></p>
                                             <p> I authorize American Cell Technology's facility to bill the yearly fee totaling $250.00 on each anniversary of the date the tissue was received at American Cell Technology's facility to the card below.
                                                  If I wish to terminate my storage with American Cell Technology, I will notify American Cell Technology of the cancellation via telephone (844-443-2355) or email (info@americancelltechnology.com)</p>
     
                                             </div>
                                         </div>
                                         </div>
                                     </div>
     
     
     
                             
     
                             <div className="form-group">
                             <div className="custom-control custom-checkbox">
                                 <input type="checkbox" className="custom-control-input" id="agree" value="yes" onChange={this.onChange} />
                                 <label className="custom-control-label" for="agree"> I understand and agree to American Cell Technology's <a href>Client Consent Terms and Conditions</a></label><br />
                                 <span style={{color: "red"}}>{this.state.errors["agree"]}</span>
                             </div>
                             </div>
     
                             <div className="col-md-6 mb-3">
                                 <p>By signing below, you confirm that you have read and fully understand the terms and conditions of this Agreement, including both Part I: Informed Consent and Part II: Client Service Agreement terms and Conditions.</p>
                             <label>Signature <span id="Signature"   className="act_required">*</span></label>
                             <SignatureCanvas  ref={(ref) => { this.sigPad = ref }} penColor='white' backgroundColor="gray"  canvasProps={{width: 500, height: 100, className: 'sigCanvas'}} />
                             <span style={{color: "red"}}> {this.state.errors["Signature"]}</span>
                             <div>
                                 <button className="btn  btn-secondary" type="button"  onClick={this.clear}>
                                 Clear
                                 </button>  &nbsp;
                                 <button className="btn btn-primary" type="button"  onClick={this.trim}>
                                 Click to Confirm Signature
                                 </button>
                             </div> 
                             
                         </div>
     
                         
     
     
     
                              <div className="choose-options-sec">
                                     {/* <button id="1" onClick={this.setcount} type="button" className="btn btn-outline-primary mr-2">Previous Page</button> */}
                                     <button id="1" onClick={this.setcount2} type="button" className="btn btn-outline-primary mr-2">Previous Page</button>
                                     <button type="submit" className="btn btn-primary">Submit</button>
                                     <br />
                                     </div>
                         {/* </div> */}
     
     
                         </div>
                         
                        }
                    </form>
                    </div> 
                    }
                            </div> }
                            </div>
                        }
                    
                            
                            </div>
                            <div class={'tab-pane fade '+ this.state.tab2} id="pills-details" role="tabpanel" aria-labelledby="pills-details-tab">
                                <div class="patient-details-content">
                            {(this.state.infectiousDiseaseFormCompleted == "true")?
                            <div>
                            <h5 style={{paddingTop:"10px"}}><i>You have already completed this form. Thank you</i></h5>
                        </div>:
                            <div className="inner-content-area">                                
                            {(this.state.payment_response)?
                                <div>
                                    <h5 style={{paddingTop:"10px"}}><i>Please check your email for order confirmation</i></h5>
                                </div>:  
                                <div>  
                            <div className="form-content-area">
                            <span style={{color: "red"}}>{this.state.api_error}</span>
                            <br />
                               
                                </div>

                        <div className="form-wrap glove-form">
                        <form className="needs-validation" noValidate onSubmit={this.onSubmitDisease}>
                            <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label><strong>Please choose from the following submission options</strong></label>
                                    <select className="form-control" id="submissionOption"  onChange={this.onChangeDisease}>
                                        <option value="">Please Select Submission Option</option>
                                        <option value="White Glove Labs">ACT White Glove Labs</option>
                                        <option value="Local Lab Submission">Local Lab Submission</option>
                                        <option value="Waiver Submission">Waiver Submission</option>
                                    </select>
                                    <span style={{color: "red"}}>{this.state.errors["submissionOption"]}</span>
                                </div>
                            </div>

                            <div className="col-md-12 submission-option-detail">
                            { (this.state.submission == "White Glove Labs")?
                            <div>
                                <h2 className="title-2">ACT White Glove Labs selection</h2>
                                <div className="submission-detail-sec">
                                    <h3 className="title-3 mb-4">Please provide shipping address for blood draw kit sent to you by ACT:</h3>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">First Name <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="FirstName" placeholder="" onChange={this.onChangeDisease} />
                                                <span style={{color: "red"}}>{this.state.errors["FirstName"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Last Name <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="LastName" placeholder="" onChange={this.onChangeDisease} />
                                                <span style={{color: "red"}}>{this.state.errors["LastName"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label">Street <span className="required">*</span></label>
                                                <div className="field-col">
                                                <input type="text" className="form-control" id="Street" placeholder="" onChange={this.onChangeDisease} />
                                                <span style={{color: "red"}}>{this.state.errors["Street"]}</span>
                                                </div>
                                                
                                            </div>
                                            </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">City <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="City" placeholder="" onChange={this.onChangeDisease} />
                                                <span style={{color: "red"}}>{this.state.errors["City"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label">State <span className="required">*</span></label>
                                                <div className="field-col">
                                                <input type="text" className="form-control" id="State" placeholder="" onChange={this.onChangeDisease} />
                                                <span style={{color: "red"}}>{this.state.errors["State"]}</span>
                                                </div>
                                                
                                            </div>
                                            </div>
                                        

                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Zip Code <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="ZipCode" placeholder="" onChange={this.onChangeDisease} />
                                                <span style={{color: "red"}}>{this.state.errors["ZipCode"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Country <span className="required">*</span></label>
                                            <div className="field-col">
                                            <input type="text" className="form-control" id="Country" placeholder="" onChange={this.onChangeDisease} />
                                            <span style={{color: "red"}}>{this.state.errors["Country"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                       
                                       
                                       
                                    </div>
                                </div>

                                <div className="mid-sections">
                                    <h2 className="title-2 mb-4">Payment Information</h2>
                                    <div className="payment-info">
                                    <div className="card">
                                        <div className="card-header">
                                        <h4>Current Payment Method Secured For Annual Banking </h4>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="customCheck2" defaultChecked />
                                            <label className="custom-control-label" htmlFor="customCheck2" />
                                        </div>
                                        </div>
                                        <div className="card-body">
                                        <div className="saved-card-detail">
                                            <div className="row">
                                            <div className="col-md-6">
                                                <label>Card Number</label>
                                                <div className="card-dtl-value">
                                                {(this.state.card_info['Credit_Card_Type'] == 'Mastercard') ?<img class="card-logo" src="images/master-card.png" alt="" /> :""}
                                                {(this.state.card_info['Credit_Card_Type'] == 'American Express') ?<img class="card-logo" src="images/card-3.svg" alt="" /> :""}
                                                {(this.state.card_info['Credit_Card_Type'] == 'Discover') ?<img class="card-logo" src="images/card-4.svg" alt="" /> :""}
                                                {(this.state.card_info['Credit_Card_Type'] == 'Visa') ?<img class="card-logo" src="images/card-1.svg" alt="" /> :""}
                
                                                {/* <img className="card-logo" src="images/master-card.png" alt="" /> */}
                                                <span>Credit Card ending in {this.state.card_last_gid}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Name on card</label>
                                                <div className="card-dtl-value">
                                                <span>{this.state.card_info['Credit_Card_Holder_First_Name']} {this.state.card_info['Credit_Card_Holder_Last_Name']} </span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Expiry</label>
                                                <div className="card-dtl-value">
                                                <span>{this.state.card_info['Month_of_Expiry']} / {this.state.card_info['Year_of_Expiry']}</span>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" id="update_card" name="update_card" className="custom-control-input" value="true" onClick={this.checkRadio3Disease}/>
                                            <label className="custom-control-label" for="update_card">Use Another Credit Card </label>
                                          
                                        </div>

                                        {(this.state.update_card == true)?
                                        <div className="card-edit-option">
                                            <div className="card-detail-form">
                                            <div className="card-detail-wrap mb-5">
                                                <h5>Credit Card Detail <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title alt="" data-original-title="Tooltip Text" /></h5>
                                                <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Credit Card Number <span className="required">*</span> </label>
                                                    <input type="text" id="Credit_Card_Number" className="form-control" placeholder="" onChange={this.onChangeDisease} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Number"]}</span>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-3">
                                                    <div className="form-group">
                                                    <label>Expiry Date <span className="required">*</span> </label>
                                                    <input type="text" className="form-control" placeholder="-- --  /  -- -- -- --" />
                                                    <span style={{color: "red"}}>{this.state.errors["CreditCard"]}</span>
                                                    </div>
                                                </div> */}

                                                <div className="col-md-4 mb-3">
                                            <label htmlFor="Month_of_Expiry">Card Expiration Date <span className="act_required">*</span></label>
                                            <select className="custom-select d-block w-100" id="Month_of_Expiry" onChange={this.onChangeDisease}  placeholder="mm" >
                                                <option value="">Select Month</option>
                                                <option value="01">01</option>
                                                <option value="02">02</option> 
                                                <option value="03">03</option> 
                                                <option value="04">04</option> 
                                                <option value="05">05</option>    
                                                <option value="06">06</option> 
                                                <option value="07">07</option> 
                                                <option value="08">08</option> 
                                                <option value="09">09</option> 
                                                <option value="10">10</option> 
                                                <option value="11">11</option>
                                                <option value="12">12</option>  
                                            </select>
                                            {/* <input type="text" className="form-control" id="Month_of_Expiry" onChange={this.onChange} placeholder="mm" required /> */}
                                            <span style={{color: "red"}}>{this.state.errors["Month_of_Expiry"]}</span>
                                            <div className="invalid-feedback">
                                            Valid Month required
                                            </div>
                                            </div>

                                            <div className="col-md-2 mb-3">
                                            <label htmlFor="creditCardExpirationYear"> &nbsp;</label>
                                            <select className="custom-select d-block w-100" id="Year_of_Expiry" onChange={this.onChangeDisease}  placeholder="YYYY" >
                                            <option value="">Year</option>
                                         
                                            <option value="2025">2025</option>
                                            <option value="2026">2026</option>
                                            <option value="2027">2027</option>
                                            <option value="2028">2028</option>
                                            <option value="2029">2029</option>
                                            <option value="2030">2030</option>
                                            <option value="2031">2031</option>
                                            <option value="2032">2032</option>
                                            </select>
                                            {/* <input type="text" className="form-control" id="creditCardExpirationYear" onChange={this.onChange} placeholder="yy" required /> */}
                                            
                                            <span style={{color: "red"}}>{this.state.errors["Year_of_Expiry"]}</span><div className="invalid-feedback">
                                            Valid Year required
                                            </div>
                                            </div>


                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                    <label>CVV <span className="required">*</span> </label>
                                                    <input type="password" id="CVV" className="form-control" placeholder="***" onChange={this.onChangeDisease} />
                                                    <span style={{color: "red"}}>{this.state.errors["CVV"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="card-logo-list">
                                                    <div><img src="images/card-1.svg" alt="" /></div>
                                                    <div><img src="images/card-2.svg" alt="" /></div>
                                                    <div><img src="images/card-3.svg" alt="" /></div>
                                                    <div><img src="images/card-4.svg" alt="" /></div>
                                                    </div>
                                                </div>
                                                </div>

                                                <div className="col-md-12">                                            
                                                         <div className="custom-control custom-checkbox">                                                    
                                                         <input type="checkbox" id="save_card" name="save_card" className="custom-control-input" value="true" onClick={this.savecard_dieses}/>
                                                         <label className="custom-control-label" for="save_card">Save this credit card on file for future payments</label>
                                                     
                                                         </div>
                                                 </div>
                                            </div>
                                            <div className="card-detail-wrap">
                                                <h5>Billing Address <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title alt="" data-original-title="Tooltip Text" /></h5>
                                                <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>First Name</label>
                                                    <input type="text" id="Credit_Card_Holder_First_Name" className="form-control" placeholder onChange={this.onChangeDisease} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_First_Name"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input type="text" id="Credit_Card_Holder_Last_Name" className="form-control" placeholder onChange={this.onChangeDisease} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_Last_Name"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Country</label>
                                                    <input type="text" id="Credit_Card_Holder_Country" className="form-control" placeholder onChange={this.onChangeDisease} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Zip/Postal Code</label>
                                                    <input type="text" id="Credit_Card_Holder_Zip"  className="form-control" placeholder onChange={this.onChangeDisease} />
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        :""}
                                        <div className="act-total">
                                            ACT White Glove Labs Total: $399.00
                                        </div>

                                        </div>
                                    </div>
                                    </div>
                                </div>                                 
                                </div>:""}
                                
                            </div>
                            { (this.state.submission == "Local Lab Submission")?
                            <div className="col-md-12 submission-option-detail">
                                <h2 className="title-2">Local Lab selection</h2>
                                <div className="form-group">
                                    <label for="lab_report_file">Please attach required Client Infectious Disease Lab Reports</label>
                                    <input type="file" className="form-control-file" id="lab_report_file" onChange={e => this.handleFileRead(e)}  />
                                    <span style={{color: "red"}}>{this.state.errors["lab_report_file"]}</span>
                                    </div>
                            </div>:''}

                           
                            <div className="col-md-12 submission-option-detail">
                            { (this.state.submission == "Waiver Submission")?
                            <div>
                                <h2 className="title-2">Waiver selection</h2>
                                <p>I agree to hold American Cell Technology harmless if i develop any infectious diseases at any time in the future subsequent to obtaining my stored cells.</p>
                                <h3 className="title-3 mb-3">Please choose the appropriate Waiver notice</h3>
                                <div className="choose-options-sec">
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="negative" name="WaiverNotice" value="Waiver notice for a client that tested negative or believes client has no known infection" className="custom-control-input" onChange={this.checkradiofordisease} />
                                        <label className="custom-control-label" for="negative">Waiver notice for a client that tested negative or believes client has no known infection</label>
                                        </div>
                                        <div className="custom-control custom-radio">
                                        <input type="radio" id="positive" name="WaiverNotice" value="Waiver notice for clients that have tested positive for a tested infection" className="custom-control-input" onChange={this.checkradiofordisease} />
                                        <label className="custom-control-label" for="positive">Waiver notice for clients that have tested positive for a tested infection</label>
                                        </div>

                                        <span style={{color: "red"}}>{this.state.errors["WaiverNotice"]}</span>
                                        
                                </div>
                                <br />
                                { (this.state.client_test == "negative")?
                                <div className="form-group">
                                    <p>
                                    Please be advised that I have either tested negative for infectious diseases for which ACT requires testing, or know that I do not have any infectious diseases. By signing this, I am aware that I cannot be infected by my cells currently stored with American Cell Technology as I am certain my cells were not contaminated with any of the diseases for which ACT requires testing. I agree to hold American Cell Technology harmless if I test positive or develop any infections at any time in the future subsequent to obtaining my stored cells.    
                                    </p>
                                </div>:""}
                                { (this.state.client_test == "positive")?
                                <div className="form-group">
                                    <p>
                                    By signing the lines below, I know that I have tested positive for one or more of the chronic infections listed in the Infectious Disease Screening Requirement document and have written them below. I understand that American Cell Technology works under current Good Manufacturing Practices and takes necessary precautions to prevent cross contamination of my cell samples. As such, I waive my rights to any additional testing for specific infectious diseases for personal cells being returned to me. I understand that while I still have one or more chronic infections still present in my body, I cannot be further infected or claim to be infected after obtaining my cells from the bank. 
                                    </p>
                                </div>                                
                                :""}
                                <div className="col-md-6 mb-3">
                                    {/* <p>By signing below, you confirm that you have read and fully understand the terms and conditions of this Agreement.</p> */}
                                    <label>Signature <span id="Signature"   className="act_required">*</span></label>
                                    <SignatureCanvas  ref={(ref) => { this.sigPad = ref }} penColor='white' backgroundColor="gray"  canvasProps={{width: 500, height: 100, className: 'sigCanvas'}} />
                                    <span style={{color: "red"}}> {this.state.errors["Signature"]}</span>
                                    <div>
                                    <button className="btn  btn-secondary" type="button"  onClick={this.clear}>
                                    Clear
                                    </button>  &nbsp;
                                    <button className="btn btn-primary" type="button"  onClick={this.trim2}>
                                    Click to Confirm Signature
                                    </button>
                                    </div> 

                                    </div>

                                </div>:""}
                              
                                <p>At American Cell Technology, we require infectious disease screening within +/- 14 days of your initial sample extraction. Please see attached <a href="../ACT Infectious Disease Evaluation.pdf" target="_blank">ACT’s Infectious Disease Screening Requirement</a>. </p>
                                {/* <a target="_blank" href="https://americancelltechnology.my.salesforce.com/sfc/p/#3j0000002ViU/a/3j000000M1Yh/HrigTo_saZs4Gqw8wSg38nWafC_YTLiKz8hNMWwWvds"> </a> */}

                                {/* <p>As the Client, you have the option of using ACT’s White Glove Labs offering, going to a local lab facility and sending us your results, or signing a waiver holding ACT harmless.											
                                </p>

                                <strong> ACT White Glove Labs</strong>
                                <p>simplifies the blood draw process from the comfort of your home or office, all for a fixed price that is often more affordable than using your local lab. </p>

                                <strong> ACT White Glove Labs</strong>
                                <p> is completed through a few quick steps:</p>

                                <ul className="glove-steps">
                                    <li>Select and pay for the ACT White Glove Labs service offering and fill out the required infomration</li>
                                    <li>Schedule a convenient time for the Mobile Phlebotomist to come to you for blood draw at <a href="https://travalab.com/american-cell-technology" target="_blank"> www.travalab.com/scheduling</a>. On Travalab Scheduling site, simply select American Cell Technology Phlebotomy and scroll to the bottom to request a convenient weekday (M-F) date and time for blood draw</li>
                                    <li>On date of service, provide Mobile Phlebotomist the blood draw kit sent to you by ACT and fill out blood draw date and time on Requisite Form included in your kit</li>
                                    <li>The Mobile Phlebotomist will handle shipping and processing your sample</li>
                                    <li>Let ACT to handle the rest!</li>
                                </ul> */}


                                <p>Clients have three options to fulfill this requirement: </p>


                                <ol>
                                <li><b><u>ACT White Glove Labs</u></b>: Complete the blood draw process at the physician's office (if available) for a fixed price, often more affordable than using a local lab. The process involves: 
                                <ul>
                                <li>Selecting and paying for the ACT White Glove Labs service on the portal and providing required information. </li>
                                <li>Scheduling a convenient blood draw appointment at the physician's office, usually on the day of the banking procedure. </li>
                                <li>Using the blood draw kit provided by ACT and completing the Requisite Form included in the kit with the blood draw date and time. </li>
                                <li>Overnight shipping the blood draw sample to the processing facility using the included shipping label. </li>
                                </ul>
                                <p></p>
                                </li>
                                <li><b><u>Local Lab Facility</u></b>: Clients can visit a local lab facility for infectious disease screening and send the results directly to ACT on the portal or via email. <p></p></li>
                                <li><b><u>Waiver</u></b>: Clients also have the option to sign a waiver holding ACT harmless if they choose not to undergo the infectious disease screening.</li>
                                </ol>

                                <p>Please choose the option that best suits your needs. If you have any questions, please do not hesitate to contact us. </p>


                                <div className="glove-table mt-0">
                                    <table width="100%" className="table table-bordered">
                                        <thead>
                                            <th>&nbsp;</th>
                                            <th>ACT White Glove Labs</th>
                                            <th>Local Lab</th>
                                        </thead>
                                        <tr>
                                            <td>Price</td>
                                            <td width="20%">$399</td>
                                            <td>Varies by lab</td>
                                        </tr>
                                        <tr>
                                            <td>Lab script included</td>
                                            <td><span className="mdi mdi-check-bold" /></td>
                                            <td><span style={{color:"red"}} className="mdi mdi-window-close" /></td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>Handled by ACT!</td>
                                            <td>Physician to provide blood work <br />script to provide to local lab. </td>
                                            {/* <td>Reach out to your physician for blood <br /> work script to provide to your local lab</td> */}
                                        </tr>
                                        <tr>
                                            <td>Convenience from medical office </td>
                                            <td><span className="mdi mdi-check-bold" /></td>
                                            <td><span style={{color:"red"}} className="mdi mdi-window-close" /></td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>Perform the blood draw conveniently <br />from the physician’s office. </td>

                                            {/* <td>ACT's Mobile Phlebotomist will come to you <br /> based on your availability and location!</td> */}
                                            <td>Schedule an additional in-person <br /> visit to a local lab for blood draw. </td>
                                            {/* <td>Schedule your in-person visit to your <br /> local lab for blood draw</td> */}
                                        </tr>
                                        <tr>
                                            <td>Results automatically submitted</td>
                                            <td><span className="mdi mdi-check-bold" /></td>
                                            <td><span style={{color:"red"}} className="mdi mdi-window-close" /></td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>No need to submitt anything as ACT <br />will receive the results directly!</td>
                                            <td>Once you receive results from the<br /> local lab, please send your report <br />to ACT through the portal or via email. </td>

                                            {/* <td>Once you receive your result from your<br /> local lab, please send ACT your report <br /> through this form or the online portal</td> */}
                                        </tr>
                                    </table>
                                </div>
                                
                                <h2 className="title-2">Client Information</h2>
                               

                                <div className="submission-detail-sec">
                                                              
                                <div className="row">
                                    <br />
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Client ID <span className="required">*</span> </label>
                                        <input type="text" id="ClientId" className="form-control" value={this.state.diseaseData['ClientId']}  disabled  onChange={this.onChangeDisease} />
                                        <span style={{color: "red"}}>{this.state.errors["ClientId"]}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Client Last Name<span className="required">*</span> </label>
                                        <input type="text" id="ClientLastname" className="form-control" value={this.state.diseaseData['ClientLastName']} disabled   onChange={this.onChangeDisease} />
                                        <span style={{color: "red"}}>{this.state.errors["ClientLastName"]}</span>
                                    </div>
                                </div>                             


                                </div>                             

                                </div>

                                
                                <br />


                                    <div className="form-group">
                                        <label>Any Infectious Diseases Client Has:</label>
                                        <textarea className="form-control" id="InfectiousDisease" onChange={this.onChangeDisease}></textarea>
                                    </div>

                                    <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="agree" value="yes" onChange={this.onChangeDisease} />
                                        <label className="custom-control-label" for="agree"> I understand and agree to American Cell Technology's <a href>Infectious Disease Terms and Conditions</a></label><br />
                                        <span style={{color: "red"}}>{this.state.errors["agree"]}</span>
                                    </div>
                                    </div>

                                    <div className="form-group">
                                    <h2 className="title-2">American Cell Technology's Infectious Disease Terms and Conditions</h2>
                                    <p>American Cell Technology requires a series of infectious disease tests prior to storing your personal stem cells at our laboratory and cryopreservation facility. Stem cell banks that sell allogenic (different donor and recipient) stem cells could never distribute any cell known to be contaminated with a variety of common chronic viral disorders. American Cell Technology only works with autologous (your own) cells. The American Disabilities Act (ADA) prohibits discrimination against individuals with disabilities in everyday activities, including receipt of medical services.  Therefore, we cannot (nor do we wish to) discriminate against our clients who arguably have disabilities under the ADA due to having tested positive for various infectious diseases.  Accordingly, we have adopted policies that allow us to provide care for patients/clients potentially infected by a variety of chronic viral conditions (e.g. HIV, Hepatitis, etc.), while ensuring that patients are fully informed of the potential consequences of storing and being treated with potentially infected cells.</p>
                                    <p>American Cell Technology requires infectious disease testing in order to protect our clients. There is no risk to our employees or risk of cross contamination due to our strict standard operating procedures and how we handle all cell samples at our facility. There is also no risk of reinfecting you with your own cells, since the majority of the diseases we require testing for cannot currently be cured.</p>
                                    <p>However, Hepatitis C can be cured and we are hopeful that many other diseases like HIV and Hepatitis B will have cures in the near future. Because of this, it is mandatory that, should you be treated and cured for an infectious disease after providing your cell sample to us for storage and treatment, we are notified immediately. We would not want to send you back cells contaminated with a virus that you no longer have (which could put you at risk of being re-infected).  Your future cooperation in keeping us updated is therefore very important for your own health.</p>
                                    </div>

                                    
                                    
                                    <button type="submit" className="btn btn-primary">Submit</button>

                            </div>

                            </div>
                            </form>
                        </div>
                    </div>
                            }
                            </div>}


                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>
                </div>
            </MainLayout>
        );
    }
}

PatientActivity.propTypes = {
    errors: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    getPhysician:PropTypes.func.isRequired,
    getClient:PropTypes.func.isRequired,
    getPaymentInfo:PropTypes.func.isRequired,
    sendConsentData:PropTypes.func.isRequired,
    setDiseaseData:PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    page_name : state.pay,
    errors: state.errors,
  });


export default connect(mapStateToProps, {getClient,getPaymentInfo,sendConsentData,setDiseaseData })(PatientActivity);