import React, { Component } from 'react';
// import PropTypes from 'prop-types';

class Left extends Component {

    constructor(props){
        super(props);
        this.state={
            nav_class:"client-information",  
            mobilemenu:"",   
            nav_color:"",      
        }
    }

    showmenu(){
        console.log('class name ----------- , '+ this.state.mobilemenu )
        // if(this.state.mobilemenu == ""){
        //   this.setState({mobilemenu :'is-open'})
        // }else{
        //   this.setState({mobilemenu :''})
        // }
      }

    componentDidMount() { 

        console.log('left part called');
        var form_color = 'simple_gray';
        var nav_color = '';
        var new_active = 'simple_gray';
        var newactive = 'simple_gray';

        if(localStorage.getItem('consentForm') == false || localStorage.getItem('consentForm') == "false") {           
            this.form_color = 'active-red';
            // this.bg_color = 'red';
            this.new_active = 'active-red';

        }
        if(localStorage.getItem('DiseaseForm') == false || localStorage.getItem('DiseaseForm') == "false") {           
            this.form_color = 'active-red';
            this.newactive = 'active-red';

        }

        if(localStorage.getItem('creditCardNumber') == '') {           
            this.nav_color = 'nav_color';
        }
            
    }



    render() {
        var form_color = '';
        var new_active = 'simple_gray';
        var newactive = 'simple_gray';

        if(localStorage.getItem('consentForm') == false || localStorage.getItem('consentForm') == "false") {           
            this.form_color = 'active-red ';
            this.new_active = 'active-red';
            }
        if(localStorage.getItem('DiseaseForm') == false || localStorage.getItem('DiseaseForm') == "false") {           
            this.form_color = 'active-red ';
            this.newactive = 'active-red';
        }
        let activeMenu = this.props.activeMenu == undefined ? 'client-information' : this.props.activeMenu;
        // let nav_color = this.props.nav_color == '' ? '' : this.props.nav_color;
        // form_color = this.props.form_color == '' ? '' : this.props.form_color;
        if(localStorage.getItem('creditCardNumber') == '') {           
            this.nav_color = 'nav_color';
        }

        // console.log('av color --------- '+ form_color);
        console.log('consent form---------- '+ localStorage.getItem('consentForm') + '---------- '+this.new_active)
       
        
        return (
        // <div className="left-section {this.state.}"> 
        <div className="left-section"> 
            <ul className="left-nav">
            <li  className={ activeMenu == 'client-information' ? " active" : ''}><a href="../client-information">Client Information</a></li>
            <li  className={ activeMenu == 'client-activity' ? "active" : ''}><a  href="../client-activity">Client Activity</a></li>
            <li className={ activeMenu == 'ACT-banking-storage-plans' ? "active" : ''}><a href="/ACT-banking-storage-plans">ACT Banking and Storage Plans</a></li>

            {/* <li className={ activeMenu == 'form-document' ? "active" : ''}><a href="../form-document">Forms and Other Documents </a></li> */}
            <li className={ activeMenu == 'form-document' ? "active" : ''} > <a  class={this.form_color}>Forms and Other Documents</a>
                        <ul class="secondary-submenu">
                            <li style={{marginTop:'5px'}}><a className={(this.new_active)?'active-red':'simple_gray'} href="../form-document/Consent-form">Consent Form</a></li>
                            <li><a className={(this.newactive)?'active-red':'simple_gray'} href="../form-document/Infectious-disease">Infectious Disease</a></li>
                        </ul>
            </li>
            {/* <li className={ activeMenu == 'client-consent' ? "active" : ''} ><a href="../client-consent">New Client Consent Form</a></li>
            <li className={ activeMenu == 'infectious-disease' ? "active" : ''}><a href="../infectious-disease">New Infectious Disease Form</a></li> */}
            <li className={ activeMenu == 'stem-cell-request' ? "active" : ''}><a href="../stem-cell-request">Stem Cell Request Form</a></li>
           {/* <li><a href>Order Patient Cells</a></li> */}
            <li className={ activeMenu == 'payment-information' ? "active" : ''}><a  class={this.nav_color} href="../payment-information">Payment Information</a></li>

            
           
            </ul>
        </div>
        );
    }
}

// Left.propTypes = {

// };

export default Left;